import API from "../../../utils/api/api";
import moment from "moment";

const AddFournisseur = async values => {
  const { errors, results, ...val } = values;
  try {
    const add = await API.post("adm_fou.php", {
      token: "22392",
      ajaxAction: "add",
      ...val,
      FOU_CREATED: moment().format(),
      FOU_UPDATED: moment().format(),
      FOU_FORM_RECEIVED: 0,
    });
    return add.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default AddFournisseur;
