"use strict";
import React, { Component } from "react";
import InfoNav from "./InfoComposants/InfoNav";
import Gestionnaires from "./composants/ContactManager/Gestionnaires";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import ButtonReturn from "../utils/button/ButtonReturn";
import history from "../utils/history";

export class ManagerContact extends Component {
  componentWillMount() {
    const { guid } = this.props.match.params;
    // history.push(`/contacts/${guid}/manager`);
  }
  render() {
    const { guid } = this.props.match.params;
    return (
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <ButtonPageTitle title="CONTACTS" />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <ButtonReturn fullname="CONTACTS" ressource="contact" />
            <InfoNav guid={guid} active={"manager"} />
            <Gestionnaires guid={guid} />
          </div>
        </div>
      </div>
    );
  }
}

export default ManagerContact;
