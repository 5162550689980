"use strict";
import React from "react";
import CrmName from "./CrmName";
import Topbar from "./topbar/Topbar";

const Body = ({ user }) => {
  return (
    <div id="kt_header" class="header header-fixed">
      <div class="container-fluid d-flex align-items-stretch justify-content-between">
        <CrmName />
        <Topbar user={user} />
      </div>
    </div>
  );
};

export default Body;
