"use strict";
import React from "react";
import { Link } from "react-router-dom";

const LogoMobile = () => {
  return (
    <Link to="/">
      <img
        alt="Logo"
        class="w-45px"
        src="assets/media/logos/logo-letter-13.png"
      />
    </Link>
  );
};

export default LogoMobile;
