function initState() {
  return {
    CON_FIRSTNAME: "",
    CON_LASTNAME: "",
    CON_PHONE: "",
    CON_EMAIL: "",
    CON_AGE: "",
    CON_JOB: "",
    CON_STATUS: "",
    CON_MARITAL_STATUS: "",
    CON_PROJECT: "",
    CON_ADDRESS: "",
    CON_CITY: "",
    CON_ZIP_CODE: "",
    CON_CHILD_COUNT: "",
    CON_COUNT_PEOPLE_IN_HOUSEHOLD: "",
    CON_RDV_TAKEN_ON: "",
    CON_DATE_RDV: "",
    CON_PLACE_RDV: "",
    CON_ISSU_RDV: "",
    CON_REVENU: "",
    CON_REVENU_FISCAL_REFERENCE: "",
    CON_REVENU_FONCIER: "",
    CON_MONTANT_LOYER: "",
    CON_CREDIT_IMMO: "",
    CON_CREDIT_CAR: "",
    CON_CREDIT_CONSO: "",
    CON_CREDIT_OTHER: "",
    CON_COMMENT: "",
    CON_NOTE: "",
    CON_CREATED_BY: "",
    CON_CREATED_BY_NAME: "",
  };
}

export default {};
export { initState };
