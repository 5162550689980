import React, { Component } from "react";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import FournisseursList from "./composants/FournisseursList";
import ButtonAdd from "../utils/button/ButtonAdd";

export class Fournisseurs extends Component {
  render() {
    return (
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <ButtonPageTitle title="FOURNISSEURS" />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <ButtonAdd ressource="cabinet" />
            <FournisseursList />
          </div>
        </div>
      </div>
    );
  }
}

export default Fournisseurs;
