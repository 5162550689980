import API from "../utils/api/api";

function authentification(USR_EMAIL, USR_PASSWORD) {
  const ajaxAction = "connect";
  return API.post("adm_ses.php", {
    USR_EMAIL,
    USR_PASSWORD,
    ajaxAction,
  });
}
/**
 *
 * @param {*} data
 * @returns
 */
function signInWithPassword(data) {
  const ajaxAction = "addUserPassword";
  return API.post("adm_usr.php", { ajaxAction, ...data });
}

function checkNewUserConnect(token) {
  const ajaxAction = "checkNewUserConnect";
  return API.post("adm_usr.php", {
    token,
    ajaxAction,
  });
}

export default {};
export { authentification, signInWithPassword, checkNewUserConnect };
