import API from "../../../utils/api/api";

const DeleteFournisseur = async guid => {
  try {
    const response = await API.post("adm_fou.php", {
      token: "22392",
      ajaxAction: "delete",
      FOU_GUID: guid,
    });
    return response.data;
  } catch (error) {
    return false;
  }
};
export default DeleteFournisseur;
