import React, { Component } from "react";
import ButtonReturn from "../utils/button/ButtonReturn";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import InfoNav from "./InfoComposants/InfoNav";
import Content from "./composants/contacts/Content";
import { findContactsByGuid } from "./controller/employee.controller";
import history from "../utils/history";
import * as ReactBootStrap from "react-bootstrap";

export class InfoEmploye extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      loading: false,
      updateError: false,
      success: false,
      error: false,
    };
  }
  async componentWillMount() {
    const { guid } = this.props.match.params;
    const contacts = await findContactsByGuid(guid);
    console.log(contacts);
    if (contacts.length) {
      await this.setState({ contacts: contacts });
    }
    // await history.push(`/employes/${guid}/contacts`);
    await this.setState({ loading: true });
  }
  render() {
    const { guid } = this.props.match.params;
    const { loading, ...data } = this.state;
    return (
      <>
        {loading ? (
          <div
            class="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <ButtonPageTitle title="EMPLOYES" />
            <div class="d-flex flex-column-fluid">
              <div class="container">
                <ButtonReturn fullname="RETOUR" ressource="employe" />
                <InfoNav guid={guid} active={"manager"} />
                <Content data={data} />
              </div>
            </div>
          </div>
        ) : (
          <ReactBootStrap.Spinner animation="border" />
        )}
      </>
    );
  }
}

export default InfoEmploye;
