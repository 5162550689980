import React from "react";
import { Link } from "react-router-dom";

const Annuler = ({ ressource }) => {
  return (
    <div>
      <div class="annuler">
        <Link
          to={`/${ressource}s`}
          class="text-dark-75 font-weight-bolder font-size-lg dropdown-menu-left"
        >
          <i class="wizard-icon flaticon2-back"></i> annuler
        </Link>
      </div>
    </div>
  );
};

export default Annuler;
