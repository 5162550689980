const OPTION_NAME = {
  OPTION_CHILD_COUNT: "OPTION_CHILD_COUNT",
  OPTION_COUNT_PEOPLE_IN_HOUSEHOLD: "OPTION_COUNT_PEOPLE_IN_HOUSEHOLD",
  OPTION_MARITAL_STATUS: "OPTION_MARITAL_STATUS",
  OPTION_PROJECT: "OPTION_PROJECT",
  OPTION_STATUS: "OPTION_STATUS",
  OPTION_PLACE_RDV: "OPTION_PLACE_RDV",
  OPTION_ISSU_RDV: "OPTION_ISSU_RDV",
  OPTION_STATUS_HOUSE: "OPTION_STATUS_HOUSE",
};

const OPTION = {
  [OPTION_NAME.OPTION_CHILD_COUNT]: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
  ],
  [OPTION_NAME.OPTION_COUNT_PEOPLE_IN_HOUSEHOLD]: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
  ],
  [OPTION_NAME.OPTION_MARITAL_STATUS]: [
    { label: "Marie", value: "Marie" },
    { label: "Concubinage", value: "Concubinage" },
    { label: "Pacse", value: "Pacse" },
    { label: "Veuf", value: "Veuf" },
    { label: "Celibataire", value: "Celibataire" },
  ],
  [OPTION_NAME.OPTION_PROJECT]: [
    { label: "INVEST", value: "INVEST" },
    { label: "RP", value: "RP" },
  ],
  [OPTION_NAME.OPTION_STATUS]: [
    { label: "CLIENT", value: "CLIENT" },
    { label: "PROSPECT", value: "PROSPECT" },
  ],
  [OPTION_NAME.OPTION_PLACE_RDV]: [
    { label: "Visio conference", value: "Visio conference" },
    { label: "Telephonique", value: "Telephonique" },
    { label: "Domicile client", value: "Domicile client" },
    { label: "Cabinet", value: "Cabinet" },
    { label: "Exterieur", value: "Exterieur" },
    { label: "Lead", value: "Lead" },
  ],
  [OPTION_NAME.OPTION_ISSU_RDV]: [
    { label: "Pas défini", value: "Pas défini" },
    { label: "VALIDE", value: "VALIDE" },
    { label: "HCI", value: "HCI" },
    { label: "HCE", value: "HCE" },
    { label: "LAPIN RDV1", value: "LAPIN RDV1" },
    { label: "LAPIN", value: "LAPIN" },
    { label: "REFUSE PAR K2M", value: "REFUSE PAR K2M" },
    { label: "CLIENT PAS INTERRESSE", value: "CLIENT PAS INTERRESSE" },
    { label: "EN ATTENTE DE RETOUR", value: "EN ATTENTE DE RETOUR" },
    { label: "ANNULE", value: "ANNULE" },
    { label: "HORS CRITERES", value: "HORS CRITERES" },
    { label: "REPORTE", value: "REPORTE" },
    { label: "A REPOSITIONNER", value: "A REPOSITIONNER" },
    { label: "INJOIGNABLE", value: "INJOIGNABLE" },
    { label: "OK2", value: "OK2" },
    { label: "REFUSE PAR LE CABINET", value: "REFUSE PAR LE CABINET" },
  ],
  [OPTION_NAME.OPTION_STATUS_HOUSE]: [
    { label: "", value: "" },
    { label: "LOCATAIRE", value: "LOCATAIRE" },
    { label: "PROPRIETAIRE", value: "PROPRIETAIRE" },
    { label: "HEBERGE A TITRE GRATUIT", value: "HEBERGE A TITRE GRATUIT" },
  ],
};
const response = {
  UNDEFINED: "Pas défini",
  INTEROGATION: "??",
};
export default {};
export { OPTION_NAME, OPTION, response };
