import React, { Component } from "react";
import HeaderMobile from "./ressources/utils/header-mobile/HeaderMobile";
import Dashboard from "./ressources/dashboard/Dashboard";
import Employes from "./ressources/employes/Employes";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import history from "./ressources/utils/history";
import Header from "./ressources/utils/body/header/Header";
import LeftBar from "./ressources/utils/body/left-bar/LeftBar";
import Footer from "./ressources/utils/body/Footer";
import Contacts from "./ressources/contacts/Contacts";
import AddContact from "./ressources/contacts/AddContact";
import Info from "./ressources/contacts/Info";
import ManagerContact from "./ressources/contacts/ManagerContact";
import DeleteContact from "./ressources/contacts/DeleteContact";
import Fournisseurs from "./ressources/fournisseurs/Fournisseurs";
import AddFournisseur from "./ressources/fournisseurs/AddFournisseur";
import InfoFournisseur from "./ressources/fournisseurs/InfoFournisseur";
import AddEmploye from "./ressources/employes/AddEmploye";
import AuthEmploye from "./ressources/employes/AuthEmploye";
import ManagerEmploye from "./ressources/employes/ManagerEmploye";
import DeleteEmploye from "./ressources/employes/DeleteEmploye";
import InfoEmploye from "./ressources/employes/InfoEmploye";
import Profil from "./ressources/profil/Profil";
import NotFoundPage from "./ressources/utils/NotFoundPage";
import Login from "./ressources/auth/Login";
import NewPassword from "./ressources/auth/NewPassword";
import Deconnexion from "./ressources/auth/Deconnexion";
import * as ReactBootStrap from "react-bootstrap";
import { getByStorage } from "./ressources/session/session.controller";
import "./App.css";
import { ROLE } from "./ressources/employes/controller/employee.const";
import AddLead from "./ressources/lead/AddLead";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      loading: false,
    };
    this.authListener = this.authListener.bind(this);
  }
  async componentDidMount() {
    await this.authListener();
  }
  async authListener() {
    const session = await getByStorage();
    if (session) {
      await this.setState({ user: session });
    }
    this.setState({ loading: true });
  }
  render() {
    const { user, loading } = this.state;

    return (
      <HashRouter hashType="slash">
        {loading ? (
          <div id="d-flex" class="d-flex flex-column flex-root">
            {user ? (
              <div class="d-flex flex-row flex-column-fluid page">
                <HeaderMobile />
                <LeftBar user={user} />
                <div
                  class="d-flex flex-column flex-row-fluid wrapper"
                  id="kt_wrapper"
                >
                  <Header user={user} />
                  {/* <Switch> */}
                  {user.USR_ROLE === ROLE.ADMIN && (
                    <>
                      <Route
                        path="/fournisseurs"
                        exact
                        component={Fournisseurs}
                      />
                      <Route
                        path="/ajouter-cabinet"
                        exact
                        component={AddFournisseur}
                      />
                      <Route
                        path="/fournisseurs/:guid"
                        exact
                        component={InfoFournisseur}
                      />
                    </>
                  )}

                  <Route path="/employes" exact component={Employes} />
                  <Route path="/ajouter-employe" exact component={AddEmploye} />
                  <Route path="/employes/:guid" exact component={InfoEmploye} />
                  <Route
                    path="/employes/:guid/auth"
                    exact
                    component={AuthEmploye}
                  />
                  <Route
                    path="/employes/:guid/delete"
                    exact
                    component={DeleteEmploye}
                  />
                  <Route
                    path="/employes/:guid/contacts"
                    exact
                    component={ManagerEmploye}
                  />

                  <Route path="/contacts" exact component={Contacts} />
                  <Route path="/ajouter-contact" exact component={AddContact} />
                  <Route path="/contacts/:guid" exact component={Info} />
                  <Route
                    path="/contacts/:guid/managers"
                    exact
                    component={ManagerContact}
                  />
                  <Route
                    path="/contacts/:guid/delete"
                    exact
                    component={DeleteContact}
                  />
                  <Route path="/lead" exact component={AddLead} />

                  <Route path="/profil" exact component={Profil} />

                  <Route path="/" exact component={Dashboard} />
                  <Route path="/deconnexion" exact component={Deconnexion} />
                  <Route
                    path="/new_password/:token"
                    exact
                    component={NewPassword}
                  />
                  {/* <Route component={NotFoundPage} />
                  </Switch> */}
                  <Footer />
                </div>
              </div>
            ) : (
              <>
                <Route path="/" exact component={Login} />
                <Route
                  path="/new_password/:token"
                  exact
                  component={NewPassword}
                />
              </>
            )}
          </div>
        ) : (
          <ReactBootStrap.Spinner animation="border" />
        )}
      </HashRouter>
    );
  }
}

export default App;
