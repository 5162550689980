const OPTION_NAME = {
  OPTION_POST: "OPTION_POST",
  OPTION_ACCESS: "OPTION_ACCESS",
};

const OPTION = {
  [OPTION_NAME.OPTION_POST]: [
    { label: "Directeur", value: "Directeur" },
    { label: "Commercial", value: "Commercial" },
    { label: "Assistante", value: "Assistante" },
    { label: "Autre", value: "Autre" },
  ],
  [OPTION_NAME.OPTION_ACCESS]: [
    { label: "GESTIONNAIRE", value: "GESTIONNAIRE" },
    { label: "ADMIN", value: "ADMIN" },
  ],
};
const response = {
  UNDEFINED: "Pas défini",
  INTEROGATION: "??",
};
const ROLE = {
  GESTIONNAIRE: "GESTIONNAIRE",
  ADMIN: "ADMIN",
};
export default {};
export { OPTION_NAME, OPTION, response, ROLE };
