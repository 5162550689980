import API from "../../utils/api/api";
import { getToken } from "../../session/session.controller";
/**
 *
 * @param {*} values
 * @returns
 */
const create = async values => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "add",
    ...values,
  });
};
/**
 *
 * @returns
 */
const getAll = async () => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "list",
  });
};
/**
 *
 * @param {*} guid
 * @returns
 */
const getByGuid = async guid => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "load",
    CON_GUID: guid,
  });
};
/**
 *
 * @param {*} guid
 * @returns
 */
const getManagersByGuid = async guid => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "listManagersByGuid",
    CON_GUID: guid,
  });
};

/**
 *
 * @param {*} data
 * @param {*} guid
 * @returns
 */
const setByGuid = async (data, guid) => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "update",
    CON_GUID: guid,
    ...data,
  });
};
/**
 *
 * @param {*} guid
 * @returns
 */
const deleteByGuid = async guid => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "delete",
    CON_GUID: guid,
  });
};
/**
 *
 * @param {*} guid
 * @returns
 */
const sendContact = async data => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "sendContact",
    ...data,
  });
};

export default {};
export {
  create,
  getAll,
  getByGuid,
  setByGuid,
  getManagersByGuid,
  deleteByGuid,
  sendContact,
};
