import React from "react";
import { Link } from "react-router-dom";

const ButtonAdd = ({ ressource }) => {
  return (
    <div class="add-contact">
      <Link
        to={`/ajouter-${ressource}`}
        class="text-dark-75 font-weight-bolder font-size-lg dropdown-menu-right"
      >
        <i class="wizard-icon flaticon2-add-1"></i> ajouter {ressource}
      </Link>
    </div>
  );
};

export default ButtonAdd;
