import React, { Component } from "react";
import { disconnect } from "./auth.controller";

export class Deconnexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  async componentWillMount() {
    await disconnect();
    document.location.href = "/";
  }
  render() {
    return <> </>;
  }
}

export default Deconnexion;
