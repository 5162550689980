import { response } from "./contact.const";
import moment from "moment";

function initProps() {
  const { UNDEFINED, INTEROGATION } = response;
  return {
    revenus: UNDEFINED,
    depenses: UNDEFINED,
    credits: UNDEFINED,
    projet: UNDEFINED,
    dateAjout: UNDEFINED,
    dateRdv: UNDEFINED,
    fournisseur: UNDEFINED,
    conName: UNDEFINED,
    job: UNDEFINED,
    email: UNDEFINED,
    adresse: UNDEFINED,
    codePostal: UNDEFINED,
    prefixe: UNDEFINED,
  };
}
function recapContactItem(contact) {
  const { UNDEFINED, INTEROGATION } = response;
  if (!contact) {
    return initProps();
  }
  return {
    tauxEndettement: tauxEndettement(contact),
    revenus:
      (parseInt(contact.CON_REVENU) || 0) +
      (parseInt(contact.CON_REVENU_FISCAL_REFERENCE) || 0) +
      (parseInt(contact.CON_REVENU_FONCIER) || 0),
    depenses: parseInt(contact.CON_MONTANT_LOYER) || 0,
    credits:
      (parseInt(contact.CON_CREDIT_IMMO) || 0) +
      (parseInt(contact.CON_CREDIT_CAR) || 0) +
      (parseInt(contact.CON_CREDIT_CONSO) || 0) +
      (parseInt(contact.CON_CREDIT_OTHER) || 0),
    projet: contact.CON_PROJECT || UNDEFINED,
    dateAjout: contact.CON_CREATED
      ? moment(contact.CON_CREATED).format("DD/MM/YYYY")
      : UNDEFINED,
    dateRdv: contact.CON_DATE_RDV ? contact.CON_DATE_RDV : UNDEFINED,
    fournisseur: contact.CON_FOURNISSEUR_LABEL || contact.CON_FOURNISSEUR_NAME,
    conName:
      contact.CON_FIRSTNAME || contact.CON_LASTNAME
        ? `${contact.CON_FIRSTNAME} ${contact.CON_LASTNAME}`
        : UNDEFINED,
    job: contact.CON_JOB || UNDEFINED,
    email: contact.CON_EMAIL || UNDEFINED,
    adresse: contact.CON_ADDRESS || UNDEFINED,
    codePostal:
      contact.CON_ZIP_CODE || contact.CON_CITY
        ? `${contact.CON_ZIP_CODE || ""} ${contact.CON_CITY || ""}`
        : UNDEFINED,
    prefixe:
      contact.CON_FIRSTNAME || contact.CON_LASTNAME
        ? `${(contact.CON_FIRSTNAME || "").charAt(0)}${(
            contact.CON_LASTNAME || ""
          ).charAt(0)}`
        : INTEROGATION,
    CON_USR_CONNECT_AUTH: contact.CON_USR_CONNECT_AUTH,
    impot: contact.CON_IMPOT || 0,
  };
}
function contactItem(contact) {
  const { UNDEFINED } = response;
  const {
    CON_DATE_RDV,
    CON_RDV_TAKEN_ON,
    CON_ISSU_RDV,
    CON_USER_CREATED_NAME,
    CON_FOURNISSEUR_NAME,
    GES_USR_FIRSTNAME,
    GES_USR_LASTNAME,
    ...con
  } = contact;
  if (!contact) {
    return initProps();
  }

  return {
    CON_FOURNISSEUR_NAME: CON_FOURNISSEUR_NAME
      ? CON_FOURNISSEUR_NAME
      : con.FOU_NAME
      ? con.FOU_NAME
      : "...",
    CON_USER_CREATED_NAME: `${con.USR_FIRSTNAME || ""} ${
      con.USR_LASTNAME || ""
    }`,
    CON_DATE_RDV_INITIAL: contact.CON_DATE_RDV,
    CON_DATE_RDV: contact.CON_DATE_RDV
      ? // ? contact.CON_DATE_RDV
        moment(contact.CON_DATE_RDV).format("DD/MM/YYYY à HH:mm")
      : UNDEFINED,
    CON_RDV_TAKEN_ON_INITIAL: contact.CON_RDV_TAKEN_ON,
    CON_RDV_TAKEN_ON: contact.CON_RDV_TAKEN_ON
      ? moment(contact.CON_RDV_TAKEN_ON).format("DD/MM/YYYY")
      : UNDEFINED,
    CON_NAME: `${con.CON_FIRSTNAME || ""} ${con.CON_LASTNAME || ""}`,
    CON_ISSU_RDV: contact.CON_ISSU_RDV ? contact.CON_ISSU_RDV : UNDEFINED,
    GESTIONNAIRE: `${GES_USR_FIRSTNAME || ""} ${GES_USR_LASTNAME || ""}`,
    ...con,
  };
}

/**
 *
 * @param {Object} contact
 * @returns
 */
function tauxEndettement({
  CON_CREDIT_CAR = 0,
  CON_CREDIT_CONSO = 0,
  CON_CREDIT_IMMO = 0,
  CON_CREDIT_OTHER = 0,
  CON_REVENU_FISCAL_REFERENCE = 0,
  CON_MONTANT_LOYER,
}) {
  const credits =
    parseInt(CON_CREDIT_IMMO) +
    parseInt(CON_CREDIT_CAR) +
    parseInt(CON_CREDIT_CONSO) +
    parseInt(CON_MONTANT_LOYER) +
    parseInt(CON_CREDIT_OTHER);
  const cSurCent = credits * 100;
  const revMensuel = CON_REVENU_FISCAL_REFERENCE / 12;
  const res = cSurCent / revMensuel;
  return (res || 0).toFixed(2);
}
export default {};
export { initProps, recapContactItem, contactItem, tauxEndettement };
