import React, { Component } from "react";
import AddNav from "./AddNav";
import Steps from "./Steps";

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
    };
    this.steps = this.steps.bind(this);
  }
  steps(step) {
    switch (step) {
      case 1:
        this.setState({
          step1: true,
          step2: false,
          step3: false,
          step4: false,
          step5: false,
        });
        break;
      case 2:
        this.setState({
          step2: true,
          step3: false,
          step4: false,
          step5: false,
        });
        break;
      case 3:
        this.setState({
          step3: true,
          step4: false,
          step5: false,
        });
        break;
      case 4:
        this.setState({
          step4: true,
          step5: false,
        });
        break;
      case 5:
        this.setState({
          step5: true,
        });
        break;
    }
  }
  render() {
    return (
      <div class="card card-custom">
        <div class="card-body p-0">
          <div
            class="wizard wizard-1"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="false"
            style={{ padding: "40px" }}
          >
            <AddNav values={this.state} />
            <Steps steps={this.steps} />
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
