"use strict";
import React, { Component } from "react";
import InfoNav from "./InfoComposants/InfoNav";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import ButtonReturn from "../utils/button/ButtonReturn";
import Recap from "./composants/ContactInfo/Recap";
import DetailsInfo from "./composants/ContactInfo/DetailsInfo";
import { initState } from "./controller/contact.model";
import { findOne, update } from "./controller/contact.controller";
import * as ReactBootStrap from "react-bootstrap";
import { contactItem } from "./controller/contact.mapper";
import history from "../utils/history";
import ListFournisseur from "../fournisseurs/controller/ListFournisseur";
import { findAll } from "../historique/controller/historique.controller";

export class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: initState(),
      loading: false,
      updateError: false,
      success: false,
      error: false,
      fournisseurs: [],
      historiques: [],
    };
    this.canUpdate = this.canUpdate.bind(this);
  }
  async canUpdate(data, changeExist) {
    await this.setState({ loading: false });
    const { exist = false, errors = false, message = {} } = changeExist;
    const { CON_GUID } = this.state.contact;
    if (exist && !errors) {
      const res = await update(data, CON_GUID);
      if (res) {
        await this.setState({ contact: res, success: true });
      } else {
        await this.setState({ updateError: true });
      }
    }
    if (errors) {
      await this.setState({ error: { CON_EMAIL: message.CON_EMAIL } });
    }
    const historiques = await findAll(CON_GUID);
    if (historiques.length) {
      await this.setState({ historiques: historiques });
    }
    await this.setState({ loading: true });
  }
  async componentWillMount() {
    const { guid } = this.props.match.params;
    const contact = await findOne(guid);
    if (contact) {
      await this.setState({ contact: contact });
    }
    const fournisseurs = await ListFournisseur();
    if (fournisseurs.data.length) {
      await this.setState({ fournisseurs: fournisseurs.data });
    }
    const historiques = await findAll(guid);
    if (historiques.length) {
      await this.setState({ historiques: historiques });
    }
    await this.setState({ loading: true });
  }
  render() {
    const { guid } = this.props.match.params;
    const { loading, ...data } = this.state;
    return (
      <>
        {loading ? (
          <div
            class="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <ButtonPageTitle title="CONTACTS" />
            <div class="d-flex flex-column-fluid">
              <div class="container">
                <ButtonReturn fullname="CONTACTS" ressource="contact" />
                <InfoNav guid={guid} active={"info"} />
                <Recap
                  data={data}
                  canUpdate={this.canUpdate}
                  fournisseurs={this.state.fournisseurs}
                />
                ,
                <div class="card card-custom " id="contact-info-affichage">
                  <div class="card-body">
                    <div class="tab-content">
                      <DetailsInfo
                        data={data}
                        canUpdate={this.canUpdate}
                        historiques={this.state.historiques}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ReactBootStrap.Spinner animation="border" />
        )}
      </>
    );
  }
}

export default Info;
