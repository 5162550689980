"use strict";
import React from "react";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import InfoNav from "./composants/InfoNav";
import Content from "./composants/Content";

const Profil = () => {
  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <ButtonPageTitle title="MON PROFIL" />
      <div class="d-flex flex-column-fluid">
        <div class="container">
          <InfoNav />
          <Content />
        </div>
      </div>
    </div>
  );
};

export default Profil;
