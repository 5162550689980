import { response } from "./employee.const";
import moment from "moment";

function initProps() {
  const { UNDEFINED } = response;
  return {
    USR_FIRSTNAME: UNDEFINED,
    USR_LASTNAME: UNDEFINED,
    USR_EMAIL: UNDEFINED,
    USR_PROFILE: UNDEFINED,
    USR_BIRTHDAY: UNDEFINED,
    USR_ADDRESS: UNDEFINED,
    USR_ZIP_CODE: UNDEFINED,
    USR_POST: UNDEFINED,
    USR_ROLE: UNDEFINED,
    USR_PHONE: UNDEFINED,
  };
}
function employeeItem(employee) {
  const { UNDEFINED } = response;
  const {
    USR_BIRTHDAY,
    USR_FIRSTNAME,
    USR_LASTNAME,
    USR_COUNT_CONTACT,
    USR_PROFILE,
    USR_CREATED,
    ...usr
  } = employee;
  if (!employee) {
    return initProps();
  }
  return {
    USR_PROFILE: USR_PROFILE || UNDEFINED,
    USR_COUNT_CONTACT: USR_COUNT_CONTACT || 0,
    USR_NAME:
      USR_FIRSTNAME || USR_LASTNAME
        ? `${USR_FIRSTNAME || ""} ${USR_LASTNAME || ""}`
        : UNDEFINED,
    USR_BIRTHDAY: employee.USR_BIRTHDAY
      ? moment(employee.USR_BIRTHDAY).format("DD/MM/YYYY ")
      : UNDEFINED,
    USR_CREATED: moment(employee.USR_CREATED).format("DD/MM/YYYY"),
    ...usr,
    USR_FIRSTNAME,
    USR_LASTNAME,
  };
}

export default {};
export { initProps, employeeItem };
