import React, { Component } from "react";
import AddNav from "./AddNav";
import Steps from "./Steps";

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step1: true,
      step2: false,
      step3: false,
    };
    this.steps = this.steps.bind(this);
  }
  steps(step) {
    switch (step) {
      case 1:
        this.setState({
          step1: true,
          step2: false,
          step3: false,
        });
        break;
      case 2:
        this.setState({
          step2: true,
          step3: false,
        });
        break;
      case 3:
        this.setState({
          step3: true,
        });
        break;
    }
  }
  render() {
    return (
      <div class="card card-custom">
        <div class="card-body p-0">
          <div
            class="wizard wizard-1"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="false"
          >
            <AddNav values={this.state} />
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <Steps steps={this.steps} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
