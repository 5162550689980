"use strict";
import React from "react";
import ButtonAdd from "../utils/button/ButtonAdd";
import ContactsList from "../contacts/composants/ContactList/ContactsList";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";

const Dashboard = () => {
  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <ButtonPageTitle title="DASHBOARD" />
      <div class="d-flex flex-column-fluid">
        <div class="container">
          <ButtonAdd ressource="contact" />
          <ContactsList />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
