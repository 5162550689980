import {
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
} from "./contact.validator";
import {
  create,
  getAll,
  getByGuid,
  setByGuid,
  getManagersByGuid,
  deleteByGuid,
  sendContact,
} from "./contact.dao";
import { contactItem } from "./contact.mapper";
import moment from "moment";
function ifErrorExistInForm(state) {
  const { step } = state;
  switch (step) {
    case 1:
      return validateStep1(state);
    case 2:
      return validateStep2(state);
    case 3:
      return validateStep3(state);
    case 4:
      return validateStep4(state);

    default:
      return false;
  }
}
/**
 *
 * @param {*} values
 * @returns
 */
async function addContact(values) {
  const format1 = "YYYY-MM-DD HH:mm:ss";
  const { CON_DATE_RDV, CON_RDV_TAKEN_ON, ...con } = values;
  const rows = {
    ...(CON_DATE_RDV
      ? { CON_DATE_RDV: moment(CON_DATE_RDV).format(format1) }
      : {}),
    ...(CON_RDV_TAKEN_ON
      ? { CON_RDV_TAKEN_ON: moment(CON_RDV_TAKEN_ON).format(format1) }
      : {}),
    CON_CREATED: moment().format(format1),
    CON_UPDATED: moment().format(format1),
    ...con,
  };
  try {
    const result = await create(rows);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
/**
 *
 * @returns
 */
async function findAll() {
  try {
    const result = await getAll();
    const { data } = result;
    let mapper = [];
    if (data.length) {
      for (const d of data) {
        mapper.push(contactItem(d));
      }
    }
    return Promise.all(mapper);
  } catch (error) {
    console.log(`findAll : ${error}`);
    return false;
  }
}
/**
 *
 * @param {*} guid
 * @returns
 */
async function findOne(guid) {
  try {
    const result = await getByGuid(guid);
    return contactItem(result.data);
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 *
 * @param {*} data
 * @param {*} guid
 * @returns
 */
async function update(data, guid) {
  const format1 = "YYYY-MM-DD HH:mm:ss";
  try {
    const { CON_DATE_RDV, CON_RDV_TAKEN_ON, ...d } = data;

    const result = await setByGuid(
      {
        ...(CON_DATE_RDV
          ? { CON_DATE_RDV: moment(CON_DATE_RDV).format(format1) }
          : {}),
        ...(CON_RDV_TAKEN_ON
          ? { CON_RDV_TAKEN_ON: moment(CON_RDV_TAKEN_ON).format(format1) }
          : {}),
        ...d,
      },
      guid
    );
    return contactItem(result.data);
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 *
 * @param {*} guid
 * @returns
 */
async function findManagersByGuid(guid) {
  try {
    const result = await getManagersByGuid(guid);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
async function deleteCon(guid) {
  try {
    const result = await deleteByGuid(guid);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
/**
 *
 * @param {*} value
 * @param {*} contacts
 * @returns
 */
async function filterBy(value, contacts) {
  if (value) {
    return await contacts.filter(c => c.CON_STATUS == value);
  }
  return contacts;
}
/**
 *
 * @param {*} value
 * @param {*} contacts
 * @returns
 */
async function trierBy(value, contacts) {
  if (value) {
    if (value == "GESTIONNAIRE") {
      return contacts.sort(compare);
    }
    let sorted = [];
    for (const s of contacts) {
      if (s[value]) {
        sorted.push(s);
      }
    }
    sorted = await Promise.all(sorted);
    return await sorted.sort((a, b) => {
      var dateA = new Date(a[value]),
        dateB = new Date(b[value]);
      return dateB - dateA;
    });
  }
  return contacts;
}
function compare(a, b) {
  if (a.GESTIONNAIRE < b.GESTIONNAIRE) {
    return 1;
  }
  if (a.GESTIONNAIRE > b.GESTIONNAIRE) {
    return -1;
  }
  return 0;
}
/**
 *
 * @param {*} rows
 * @param {*} field
 * @param {*} value
 * @returns
 */
const searchListCon = async (
  rows,
  field,
  value,
  dateBegin = moment(),
  dateEnd = moment()
) => {
  let results = "";
  switch (field) {
    case "CON_RDV_TAKEN_ON_INITIAL":
      results = await rows.filter(r => {
        if (r[field]) {
          return (
            moment(r[field]).format("YYYY-MM-DD") >=
              moment(dateBegin).format("YYYY-MM-DD") &&
            moment(r[field]).format("YYYY-MM-DD") <=
              moment(dateEnd).format("YYYY-MM-DD")
          );
        }
      });
      return results;
    case "CON_DATE_RDV_INITIAL":
      results = await rows.filter(r => {
        if (r[field]) {
          return (
            moment(r[field]).format("YYYY-MM-DD") >=
              moment(dateBegin).format("YYYY-MM-DD") &&
            moment(r[field]).format("YYYY-MM-DD") <=
              moment(dateEnd).format("YYYY-MM-DD")
          );
        }
      });
      return results;
    case "CON_CREATED":
      results = await rows.filter(r => {
        if (r[field]) {
          return (
            moment(r[field]).format("YYYY-MM-DD") >=
              moment(dateBegin).format("YYYY-MM-DD") &&
            moment(r[field]).format("YYYY-MM-DD") <=
              moment(dateEnd).format("YYYY-MM-DD")
          );
        }
      });
      return results;
    case "CON_ZIP_CODE":
      results = await rows.filter(r => {
        let v = r[field] ? r[field].toString() : null;
        return v && v.includes(value.toLowerCase());
      });
      return results;
    case "FOU_NAME":
      results = await rows.filter(r => {
        let v = r["CON_FOURNISSEUR_LABEL"]
          ? r["CON_FOURNISSEUR_LABEL"].toLowerCase()
          : r[field]
          ? r[field].toLowerCase()
          : null;
        return v && v.includes(value.toLowerCase());
      });
      return results;
    default:
      results = await rows.filter(r => {
        let v = r[field] ? r[field].toLowerCase() : null;
        return v && v.includes(value.toLowerCase());
      });
      return results;
  }
};
/**
 *
 * @param {Object} data
 * @returns
 */
async function canSendMail(data) {
  try {
    const result = await sendContact(data);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export default {};
export {
  ifErrorExistInForm,
  addContact,
  findAll,
  findOne,
  update,
  findManagersByGuid,
  deleteCon,
  filterBy,
  searchListCon,
  canSendMail,
  trierBy,
};
