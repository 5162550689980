function initState() {
  return {
    USR_FIRSTNAME: "",
    USR_LASTNAME: "",
    USR_EMAIL: "",
    USR_PROFILE: "",
    USR_BIRTHDAY: "",
    USR_ADDRESS: "",
    USR_ZIP_CODE: "",
    USR_POST: "",
    USR_ROLE: "",
    USR_PHONE: "",
  };
}

export default {};
export { initState };
