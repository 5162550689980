export default function validateFournisseur(values) {
  let errors = {};
  const { FOU_NAME, FOU_EMAIL, FOU_ZIP_CODE, FOU_ADDRESS, FOU_COUNTRY } =
    values;
  if (!FOU_NAME.trim()) {
    errors.FOU_NAME = "Nom du cabinet obligatoire";
  }
  if (!FOU_EMAIL) {
    errors.FOU_EMAIL = "Email obligatoire";
  } else if (!/\S+@\S+\.\S+/.test(FOU_EMAIL)) {
    errors.FOU_EMAIL = "L'adresse Email est invalide";
  }
  if (!FOU_ZIP_CODE) {
    errors.FOU_ZIP_CODE = "Code postal obligatoire";
  }
  if (!FOU_ADDRESS) {
    errors.FOU_ADDRESS = "L'adresse du cabinet obligatoire";
  }
  if (!FOU_COUNTRY) {
    errors.FOU_COUNTRY = "Le pays du cabinet obligatoire";
  }

  if (!Object.entries(errors).length) {
    errors = null;
  }
  return errors;
}
