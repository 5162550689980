import React, { Component } from "react";
import { OPTION } from "../controller/contact.const";
import Select from "react-select";
export class Step1 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values = {}, inputChange } = this.props;
    const errors = values.errors || {};

    return (
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h3 class="mb-10 font-weight-bold text-dark">Informations générales</h3>
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>Prénom</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_FIRSTNAME"
                onChange={inputChange("CON_FIRSTNAME")}
                placeholder="Valerie"
                defaultValue={values.CON_FIRSTNAME}
              />
              <span className="review-form">
                {errors && errors.CON_FIRSTNAME}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Nom</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_LASTNAME"
                onChange={inputChange("CON_LASTNAME")}
                placeholder="MAISONNAVE"
                defaultValue={values.CON_LASTNAME}
              />
              <span className="review-form">
                {errors && errors.CON_LASTNAME}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Téléphone</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_PHONE"
                onChange={inputChange("CON_PHONE")}
                placeholder="0608XXXXXX"
                defaultValue={values.CON_PHONE}
              />
              <span className="review-form">{errors && errors.CON_PHONE}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Email</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_EMAIL"
                onChange={inputChange("CON_EMAIL")}
                placeholder="valeri@email.fr"
                defaultValue={values.CON_EMAIL}
              />
              <span className="review-form">{errors && errors.CON_EMAIL}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Âge</label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_AGE"
                onChange={inputChange("CON_AGE")}
                defaultValue={values.CON_AGE}
              />
              <span className="review-form">{errors && errors.CON_AGE}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Profession</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_JOB"
                onChange={inputChange("CON_JOB")}
                placeholder="Informaticien"
                defaultValue={values.CON_JOB}
              />
              <span className="review-form">{errors && errors.CON_PHONE}</span>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label>Nombre d'enfant à charge </label>
              <Select
                name="CON_CHILD_COUNT"
                options={OPTION.OPTION_CHILD_COUNT}
                value={{
                  value: values.CON_CHILD_COUNT,
                  label: values.CON_CHILD_COUNT,
                }}
                onChange={inputChange("CON_CHILD_COUNT")}
                defaultValue={{ label: 0, value: 0 }}
              />
              <span className="review-form">
                {errors && errors.CON_CHILD_COUNT}
              </span>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label>Nombre de personne dans le foyer </label>
              <Select
                name="CON_COUNT_PEOPLE_IN_HOUSEHOLD"
                options={OPTION.OPTION_COUNT_PEOPLE_IN_HOUSEHOLD}
                value={{
                  value: values.CON_COUNT_PEOPLE_IN_HOUSEHOLD,
                  label: values.CON_COUNT_PEOPLE_IN_HOUSEHOLD,
                }}
                onChange={inputChange("CON_COUNT_PEOPLE_IN_HOUSEHOLD")}
                defaultValue={{ label: "0", value: "0" }}
              />
              <span className="review-form">
                {errors && errors.CON_COUNT_PEOPLE_IN_HOUSEHOLD}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>Statut matrimonial</label>
              <Select
                name="CON_MARITAL_STATUS"
                options={OPTION.OPTION_MARITAL_STATUS}
                value={{
                  value: values.CON_MARITAL_STATUS,
                  label: values.CON_MARITAL_STATUS,
                }}
                onChange={inputChange("CON_MARITAL_STATUS")}
              />
              <span className="review-form">
                {errors && errors.CON_MARITAL_STATUS}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Statut (Locataire, ...)</label>
              <Select
                name="CON_STATUS_HOUSE"
                options={OPTION.OPTION_STATUS_HOUSE}
                value={{
                  value: values.CON_STATUS_HOUSE,
                  label: values.CON_STATUS_HOUSE,
                }}
                onChange={inputChange("CON_STATUS_HOUSE")}
              />
              <span className="review-form">
                {errors && errors.CON_STATUS_HOUSE}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Projet</label>
              <Select
                name="CON_PROJECT"
                options={OPTION.OPTION_PROJECT}
                value={{
                  value: values.CON_PROJECT,
                  label: values.CON_PROJECT,
                }}
                onChange={inputChange("CON_PROJECT")}
              />
              <span className="review-form">
                {errors && errors.CON_PROJECT}
              </span>
            </div>
          </div>
          <div class="col-xl-4" style={{ display: "none" }}>
            <div class="form-group">
              <label>Statut du contact</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_STATUS"
                options={OPTION.OPTION_STATUS}
                value="PROSPECT"
              />
              <span className="review-form">{errors && errors.CON_STATUS}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Adresse</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_ADDRESS"
                onChange={inputChange("CON_ADDRESS")}
                placeholder="11 Rue de Jacques"
                defaultValue={values.CON_ADDRESS}
              />
              <span className="review-form">
                {errors && errors.CON_ADDRESS}
              </span>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label>Ville</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_CITY"
                onChange={inputChange("CON_CITY")}
                placeholder="Bayonne"
                defaultValue={values.CON_CITY}
              />
              <span className="review-form">{errors && errors.CON_CITY}</span>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="form-group">
              <label>Code postal</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="CON_ZIP_CODE"
                onChange={inputChange("CON_ZIP_CODE")}
                placeholder="43113"
                defaultValue={values.CON_ZIP_CODE}
              />
              <span className="review-form">
                {errors && errors.CON_ZIP_CODE}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step1;
