"use strict";
import React from "react";

const Languages = () => {
  return (
    <div class="dropdown">
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <img
            class="img-langue  h-20px w-20px rounded-sm"
            src="assets/media/svg/flags/195-france.svg"
            alt=""
          />
          <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            Langue
          </span>
        </div>
      </div>
      <div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
        <ul class="navi navi-hover py-4">
          <li class="navi-item">
            <a href="#" class="navi-link">
              <span class="symbol symbol-20 mr-3">
                <img
                  class=""
                  src="assets/media/svg/flags/195-france.svg"
                  alt=""
                />
              </span>
              Français
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Languages;
