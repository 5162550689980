import API from "../../utils/api/api";
import { getToken } from "../../session/session.controller";
/**
 *
 * @param {*} values
 * @returns
 */
const create = async (values) => {
  const token = await getToken();
  return await API.post("adm_usr.php", {
    token,
    ajaxAction: "add",
    ...values,
  });
};
/**
 *
 * @returns
 */
const getAll = async () => {
  const token = await getToken();
  return await API.post("adm_usr.php", {
    token,
    ajaxAction: "list",
  });
};
/**
 *
 * @param {*} guid
 * @returns
 */
const getByGuid = async (guid) => {
  const token = await getToken();
  return await API.post("adm_usr.php", {
    token,
    ajaxAction: "load",
    USR_GUID: guid,
  });
};

/**
 *
 * @param {*} data
 * @param {*} guid
 * @returns
 */
const setByGuid = async (data, guid) => {
  const token = await getToken();
  return await API.post("adm_usr.php", {
    token,
    ajaxAction: "update",
    USR_GUID: guid,
    ...data,
  });
};

const deleteByGuid = async (guid) => {
  const token = await getToken();
  return await API.post("adm_usr.php", {
    token,
    ajaxAction: "delete",
    USR_GUID: guid,
  });
};
/**
 *
 * @param {*} guid
 * @returns
 */
const getContactsByRhGuid = async (guid) => {
  const token = await getToken();
  return await API.post("adm_con.php", {
    token,
    ajaxAction: "listByRhGuid",
    USR_GUID: guid,
  });
};
export default {};
export {
  create,
  getAll,
  getByGuid,
  setByGuid,
  deleteByGuid,
  getContactsByRhGuid,
};
