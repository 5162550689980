import React, { Component } from "react";
import "../../../App.css";

export class Step5 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  previous = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values = {} } = this.props;
    return (
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h4 class="mb-10 font-weight-bold text-dark">
          Passez en revue vos détails et enregistrez
        </h4>
        <h6 class="font-weight-bolder mb-3">1. Informations générales</h6>
        <div class="text-dark-50 line-height-lg">
          <div>
            Prénom:{" "}
            <span className="review-form"> {values.CON_FIRSTNAME} </span>
          </div>
          <div>
            Nom: <span className="review-form"> {values.CON_LASTNAME}</span>
          </div>
          <div>
            Téléphone: <span className="review-form"> {values.CON_PHONE}</span>
          </div>
          <div>
            Email: <span className="review-form"> {values.CON_EMAIL}</span>
          </div>
          <div>
            Âge
            <span className="review-form"> {values.CON_AGE}</span>
          </div>
          <div>
            Profession: <span className="review-form"> {values.CON_JOB}</span>
          </div>
          <div>
            Nombre d'enfant à charge:{" "}
            <span className="review-form"> {values.CON_CHILD_COUNT}</span>
          </div>
          <div>
            Nombre de personne dans le foyer:{" "}
            <span className="review-form">
              {" "}
              {values.CON_COUNT_PEOPLE_IN_HOUSEHOLD}
            </span>
          </div>
          <div>
            Statut matrimonial:{" "}
            <span className="review-form"> {values.CON_MARITAL_STATUS}</span>
          </div>
          <div>
            Statut (Locataire, ...):
            <span className="review-form"> {values.CON_STATUS_HOUSE}</span>
          </div>
          <div>
            Projet: <span className="review-form"> {values.CON_PROJECT}</span>
          </div>
          <div>
            Statut: <span className="review-form"> {values.CON_STATUS}</span>
          </div>
          <div>
            Adresse: <span className="review-form"> {values.CON_ADDRESS}</span>
          </div>
          <div>
            Ville: <span className="review-form"> {values.CON_CITY}</span>
          </div>
          <div>
            Code postal:{" "}
            <span className="review-form"> {values.CON_ZIP_CODE}</span>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <h6 class="font-weight-bolder mb-3">2. Revenues & Charges</h6>
        <div class="text-dark-50 line-height-lg">
          <div>
            Revenu fiscal de référence:{" "}
            <span className="review-form">
              {" "}
              {values.CON_REVENU_FISCAL_REFERENCE}
            </span>
          </div>
          <div>
            Revenu foncier:{" "}
            <span className="review-form"> {values.CON_REVENU}</span>
          </div>
          <div>
            Crédit automobile:{" "}
            <span className="review-form"> {values.CON_CREDIT_CAR}</span>
          </div>
          <div>
            Montant loyer:{" "}
            <span className="review-form"> {values.CON_MONTANT_LOYER}</span>
          </div>
          <div>
            Crédit conso:{" "}
            <span className="review-form"> {values.CON_CREDIT_CONSO}</span>
          </div>
          <div>
            Crédit immobilier:{" "}
            <span className="review-form"> {values.CON_CREDIT_IMMO}</span>
          </div>
          <div>
            Montant de l'impôt:
            <span className="review-form"> {values.CON_IMPOT}</span>
          </div>
          <div>
            Autres crédits:{" "}
            <span className="review-form"> {values.CON_CREDIT_OTHER}</span>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <h6 class="font-weight-bolder mb-3">3. RDV</h6>
        <div class="text-dark-50 line-height-lg">
          <div>
            Rdv pris le:{" "}
            <span className="review-form"> {values.CON_RDV_TAKEN_ON}</span>
          </div>
          <div>
            Date du rdv:{" "}
            <span className="review-form"> {values.CON_DATE_RDV}</span>
          </div>
          <div>
            Lieu du rdv:{" "}
            <span className="review-form"> {values.CON_PLACE_RDV}</span>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <h6 class="font-weight-bolder mb-3">4. Autres Informations</h6>
        <div class="text-dark-50 line-height-lg">
          <div>
            Commentaire:{" "}
            <span className="review-form"> {values.CON_COMMENT}</span>
          </div>
        </div>
        <div class="text-dark-50 line-height-lg">
          <div>
            Note direction:{" "}
            <span className="review-form"> {values.CON_NOTE}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.previous}
            >
              Précédent
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Enregistez
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step5;
