import API from "../../../utils/api/api";
import token from "../../../utils/api/token";

const GetAllFournisseurs = async () => {
  const tok = "22392";
  const val = { token: tok, ajaxAction: "list" };
  try {
    const response = await API.post("adm_fou.php", val);
    if (response) {
      return response.data;
    }
    return [];
  } catch (error) {
    console.log(error);

    return [];
  }
};

export default GetAllFournisseurs;
