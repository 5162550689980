const validatorInfo = ({
  FOU_NAME,
  FOU_ZONE,
  FOU_ZIP_CODE,
  FOU_ADDRESS,
  FOU_COUNTRY,
  FOU_EMAIL,
  FOU_PHONE,
  fou,
}) => {
  let change = {
    exist: false,
    errors: false,
    message: {
      FOU_EMAIL: "",
    },
  };

  if (FOU_EMAIL.trim() && fou.FOU_EMAIL !== FOU_EMAIL.trim()) {
    if (!/\S+@\S+\.\S+/.test(FOU_EMAIL)) {
      change = {
        exist: false,
        errors: true,
        message: { FOU_EMAIL: "Adresse Email invalide" },
      };
      return change;
    } else {
      change = {
        exist: true,
      };
    }
  }
  if (FOU_ADDRESS.trim() && FOU_ADDRESS.trim() !== fou.FOU_ADDRESS) {
    change = {
      exist: true,
    };
  }
  if (FOU_ZIP_CODE.trim() && FOU_ZIP_CODE.trim() !== fou.FOU_ZIP_CODE) {
    change = {
      exist: true,
    };
  }
  if (FOU_NAME.trim() && FOU_NAME.trim() !== fou.FOU_NAME) {
    change = {
      exist: true,
    };
  }
  if (FOU_ZONE.trim() && FOU_ZONE.trim() !== fou.FOU_ZONE) {
    change = {
      exist: true,
    };
  }
  if (FOU_COUNTRY.trim() && FOU_COUNTRY.trim() !== fou.FOU_COUNTRY) {
    change = {
      exist: true,
    };
  }
  if (FOU_PHONE.trim() && FOU_PHONE.trim() !== fou.FOU_PHONE) {
    change = {
      exist: true,
    };
  }

  return change;
};

export default validatorInfo;
