import React, { Component } from "react";
import { Link } from "react-router-dom";
import { findAll } from "../controller/employee.controller";
import * as ReactBootStrap from "react-bootstrap";
import history from "../../utils/history";

export class EmployesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employes: [],
      loading: false,
      valueSearch: "",
      field: "CON_NAME",
    };
    this.inputChange = this.inputChange.bind(this);
  }
  inputChange = (input) => async (e) => {};
  async componentWillMount() {
    const employes = await findAll();
    if (employes.length) {
      await this.setState({ employes: employes });
    }
    // await history.push("/employes");
    this.setState({ loading: true });
  }
  render() {
    const { employes, loading } = this.state;
    return (
      <div class="card card-custom gutter-b">
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0 tb-id"> id</th>
                  <th class="tb-name">
                    <span class="text-primary">Prénom Nom</span>
                  </th>
                  <th class="tb-name">
                    <span class="text-primary">Contact Affecté</span>
                  </th>
                  <th class="tb-name">
                    <span class="text-primary">Date d'ajout</span>
                  </th>
                  <th class="tb-name">
                    <span class="text-primary">Profil</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  employes.length ? (
                    employes.map((e, i) => {
                      return (
                        <>
                          <tr>
                            <td class="pl-0">
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {i + 1}
                              </span>
                            </td>
                            <td>
                              <Link
                                to={`/employes/${e.USR_GUID}`}
                                class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                              >
                                {e.USR_NAME}
                              </Link>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {e.USR_COUNT_CONTACT}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {e.USR_CREATED}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {e.USR_PROFILE}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                          marginTop: "30px",
                        }}
                      >
                        <Link
                          to="/ajouter-employé"
                          class="btn btn-success font-weight-bolder font-size-sm"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  opacity="0.3"
                                ></path>
                                <path
                                  d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                ></path>
                              </g>
                            </svg>
                          </span>
                          Ajouter un nouveau employé
                        </Link>
                      </div>
                      <div style={{ marginBottom: "100px" }}></div>
                    </>
                  )
                ) : (
                  <ReactBootStrap.Spinner animation="border" />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployesList;
