function validateStep1(values) {
  let errors = {};
  const {
    // information générale
    CON_FIRSTNAME,
    CON_LASTNAME,
    CON_PHONE,
    CON_EMAIL,
    CON_AGE,
    CON_MARITAL_STATUS,
    CON_JOB,
    CON_STATUS,
    CON_PROJECT,
    CON_ADDRESS,
    CON_CITY,
    CON_ZIP_CODE,
    CON_CHILD_COUNT,
    CON_COUNT_PEOPLE_IN_HOUSEHOLD,
  } = values;
  if (
    !CON_EMAIL ||
    !CON_AGE ||
    !CON_CITY ||
    !CON_ZIP_CODE ||
    !CON_MARITAL_STATUS
  ) {
    if (!CON_EMAIL) {
      errors.CON_EMAIL = "Email obligatoire";
    }
    if (!CON_AGE) {
      errors.CON_AGE = "L'âge est obligatoire";
    }
    if (!CON_CITY) {
      errors.CON_CITY = "La ville est obligatoire";
    }
    if (!CON_ZIP_CODE) {
      errors.CON_ZIP_CODE = "Le code postal est obligatoire";
    }
    if (!CON_MARITAL_STATUS) {
      errors.CON_MARITAL_STATUS = "Le statut matrimonial est obligatoire";
    }
    if (!CON_PHONE) {
      errors.CON_PHONE = "Le numéro de téléphone est obligatoire";
    }
    return errors;
  } else if (!/\S+@\S+\.\S+/.test(CON_EMAIL)) {
    errors.CON_EMAIL = "L'adresse Email est invalide";
    return errors;
  }

  return false;
}
function validateStep2(values) {
  let errors = {};
  const {
    // Revenue
    CON_REVENU,
    CON_REVENU_FISCAL_REFERENCE,
    CON_REVENU_FONCIER,
    // Charges
    CON_MONTANT_LOYER,
    CON_CREDIT_IMMO,
    CON_CREDIT_CAR,
    CON_CREDIT_CONSO,
    CON_CREDIT_OTHER,
    CON_IMPOT,
  } = values;
  if (!CON_REVENU_FISCAL_REFERENCE || !CON_IMPOT) {
    if (!CON_REVENU_FISCAL_REFERENCE) {
      errors.CON_REVENU_FISCAL_REFERENCE =
        "Le revenu fiscal de référence obligatoire";
    }
    if (!CON_IMPOT) {
      errors.CON_IMPOT = "L'impôt est obligatoire";
    }
    return errors;
  }
  return false;
}
function validateStep3(values) {
  let errors = {};
  const {
    // RDV
    CON_RDV_TAKEN_ON,
    CON_DATE_RDV,
    CON_PLACE_RDV,
    CON_ISSU_RDV,
  } = values;
  if (!CON_RDV_TAKEN_ON || !CON_DATE_RDV || !CON_PLACE_RDV) {
    if (!CON_RDV_TAKEN_ON) {
      errors.CON_RDV_TAKEN_ON = "Champs obligatoire";
    }
    if (!CON_DATE_RDV) {
      errors.CON_DATE_RDV = "Champs obligatoire";
    }
    if (!CON_PLACE_RDV) {
      errors.CON_PLACE_RDV = "Champs obligatoire";
    }
    return errors;
  }
  return false;
}
function validateStep4(values) {
  let errors = {};
  const {
    // Autres
    CON_COMMENT,
    CON_NOTE,
  } = values;

  return false;
}

const validatorUpdate = (
  {
    CON_FIRSTNAME,
    CON_LASTNAME,
    CON_PHONE,
    CON_EMAIL,
    CON_AGE,
    CON_JOB,
    CON_STATUS,
    CON_MARITAL_STATUS,
    CON_PROJECT,
    CON_ADDRESS,
    CON_CITY,
    CON_ZIP_CODE,
    CON_CHILD_COUNT,
    CON_COUNT_PEOPLE_IN_HOUSEHOLD,
    CON_RDV_TAKEN_ON,
    CON_DATE_RDV,
    CON_PLACE_RDV,
    CON_ISSU_RDV,
    CON_REVENU,
    CON_REVENU_FISCAL_REFERENCE,
    CON_REVENU_FONCIER,
    CON_MONTANT_LOYER,
    CON_CREDIT_IMMO,
    CON_CREDIT_CAR,
    CON_CREDIT_CONSO,
    CON_CREDIT_OTHER,
    CON_COMMENT,
    CON_NOTE,
    CON_IMPOT,
    CON_STATUS_HOUSE,
    CON_FOURNISSEUR_GUID,
  },
  contact
) => {
  let change = {
    exist: false,
    errors: false,
    message: {},
  };

  if (CON_EMAIL && contact.CON_EMAIL !== CON_EMAIL.trim()) {
    if (!/\S+@\S+\.\S+/.test(CON_EMAIL)) {
      change.errors = true;
      change.message = { CON_EMAIL: "Adresse Email invalide" };
    } else {
      change.exist = true;
    }
  }
  if (CON_FIRSTNAME && CON_FIRSTNAME.trim() !== contact.CON_FIRSTNAME) {
    change.exist = true;
  }
  if (CON_LASTNAME && CON_LASTNAME.trim() !== contact.CON_LASTNAME) {
    change.exist = true;
  }
  if (CON_PHONE && CON_PHONE.trim() !== contact.CON_PHONE) {
    change.exist = true;
  }
  if (CON_AGE) {
    change.exist = true;
  }
  if (CON_JOB && CON_JOB.trim() !== contact.CON_JOB) {
    change.exist = true;
  }
  if (CON_STATUS && CON_STATUS.trim() !== contact.CON_STATUS) {
    change.exist = true;
  }
  if (CON_PLACE_RDV && CON_PLACE_RDV.trim() !== contact.CON_PLACE_RDV) {
    change.exist = true;
  }
  if (CON_DATE_RDV) {
    change.exist = true;
  }
  if (CON_RDV_TAKEN_ON) {
    change.exist = true;
  }

  if (
    CON_COUNT_PEOPLE_IN_HOUSEHOLD &&
    CON_COUNT_PEOPLE_IN_HOUSEHOLD.trim() !==
      contact.CON_COUNT_PEOPLE_IN_HOUSEHOLD
  ) {
    change.exist = true;
  }
  if (CON_CHILD_COUNT && CON_CHILD_COUNT.trim() !== contact.CON_CHILD_COUNT) {
    change.exist = true;
  }
  if (CON_ZIP_CODE && CON_ZIP_CODE.trim() !== contact.CON_ZIP_CODE) {
    change.exist = true;
  }
  if (CON_CITY && CON_CITY.trim() !== contact.CON_CITY) {
    change.exist = true;
  }
  if (CON_ADDRESS && CON_ADDRESS.trim() !== contact.CON_ADDRESS) {
    change.exist = true;
  }
  if (CON_PROJECT && CON_PROJECT.trim() !== contact.CON_PROJECT) {
    change.exist = true;
  }
  if (
    CON_MARITAL_STATUS &&
    CON_MARITAL_STATUS.trim() !== contact.CON_MARITAL_STATUS
  ) {
    change.exist = true;
  }
  if (CON_NOTE && CON_NOTE.trim() !== contact.CON_NOTE) {
    change.exist = true;
  }
  if (CON_COMMENT && CON_COMMENT.trim() !== contact.CON_COMMENT) {
    change.exist = true;
  }
  if (
    CON_CREDIT_OTHER &&
    CON_CREDIT_OTHER.trim() !== contact.CON_CREDIT_OTHER
  ) {
    change.exist = true;
  }
  if (
    CON_CREDIT_CONSO &&
    CON_CREDIT_CONSO.trim() !== contact.CON_CREDIT_CONSO
  ) {
    change.exist = true;
  }
  if (CON_CREDIT_CAR && CON_CREDIT_CAR.trim() !== contact.CON_CREDIT_CAR) {
    change.exist = true;
  }
  if (CON_CREDIT_IMMO && CON_CREDIT_IMMO.trim() !== contact.CON_CREDIT_IMMO) {
    change.exist = true;
  }

  if (
    CON_MONTANT_LOYER &&
    CON_MONTANT_LOYER.trim() !== contact.CON_MONTANT_LOYER
  ) {
    change.exist = true;
  }
  if (
    CON_REVENU_FONCIER &&
    CON_REVENU_FONCIER.trim() !== contact.CON_REVENU_FONCIER
  ) {
    change.exist = true;
  }
  if (
    CON_REVENU_FISCAL_REFERENCE &&
    CON_REVENU_FISCAL_REFERENCE.trim() !== contact.CON_REVENU_FISCAL_REFERENCE
  ) {
    change.exist = true;
  }
  if (CON_REVENU && CON_REVENU.trim() !== contact.CON_REVENU) {
    change.exist = true;
  }
  if (CON_ISSU_RDV && CON_ISSU_RDV.trim() !== contact.CON_ISSU_RDV) {
    change.exist = true;
  }
  if (CON_IMPOT && CON_IMPOT.trim() !== contact.CON_IMPOT) {
    change.exist = true;
  }
  if (
    CON_STATUS_HOUSE &&
    CON_STATUS_HOUSE.trim() !== contact.CON_STATUS_HOUSE
  ) {
    change.exist = true;
  }
  if (
    CON_FOURNISSEUR_GUID &&
    CON_FOURNISSEUR_GUID.trim() !== contact.CON_FOURNISSEUR_GUID
  ) {
    change.exist = true;
  }
  return change;
};

export default {};
export {
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
  validatorUpdate,
};
