import React, { Component } from "react";
import { deleteEmployee } from "../../controller/employee.controller";
import { webPath } from "../../../utils/api/api.const";
export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      hasDelete: false,
    };
    this.hasDelete = this.hasDelete.bind(this);
  }
  async hasDelete() {
    const { uuid } = this.state;
    this.setState({ hasDelete: true });
    await deleteEmployee(uuid);
    await setTimeout(() => {
      document.location.href = `${webPath.dev}employes`;
    }, 3000);
  }
  componentWillMount() {
    const { guid } = this.props;
    this.setState({ uuid: guid });
  }
  render() {
    const { hasDelete } = this.state;

    return (
      <div class="tab-pane px-7" id="kt_user_edit_tab_3" role="tabpanel">
        <div class="card-body">
          <div class="row" style={{ backgroundColor: "white" }}>
            <div class="col-xl-2"></div>
            <div class="col-xl-7">
              <div class="row mb-5">
                <div class="col-9">
                  <div class="row">
                    <div class="col-9">
                      <h6 class="text-dark font-weight-bold mb-10">
                        Supprimer ce dossier employé
                      </h6>
                    </div>
                  </div>
                  <div class="alert alert-custom  fade show py-4" role="alert">
                    <div class="alert-icon">
                      <i class="flaticon-warning"></i>
                    </div>
                    {hasDelete ? (
                      <div class="alert-text font-weight-bold">
                        Employé supprimé avec succès !!! <br />
                        Vous serez redirigé vers la liste des employés dans 3
                        secondes
                      </div>
                    ) : (
                      <div class="alert-text font-weight-bold">
                        En supprimant ce dossier, toutes les informations
                        d'employé seront perdues.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!hasDelete && (
          <div class="card-footer pb-0" style={{ paddingTop: 0 }}>
            <div class="row">
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-9">
                    <a
                      onClick={this.hasDelete}
                      class="btn btn-light-primary font-weight-bold"
                    >
                      Supprimer employé
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Content;
