import React, { Component } from "react";
import "../../App.css";
import { validatorSignIn, signIn, checkToken } from "./auth.controller";
import * as ReactBootStrap from "react-bootstrap";

export class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      USR_REWRITE_PASSWORD: "",
      USR_PASSWORD: "",
      userExist: false,
      success: false,
      errors: {
        USR_REWRITE_PASSWORD: "",
        USR_PASSWORD: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    const { USR_REWRITE_PASSWORD, USR_PASSWORD, userExist } = this.state;
    const { exist, message } = await validatorSignIn(this.state);
    if (exist) {
      await this.setState({
        errors: {
          USR_REWRITE_PASSWORD: message.USR_REWRITE_PASSWORD,
          USR_PASSWORD: message.USR_PASSWORD,
        },
      });
    } else {
      await this.setState({
        errors: {
          USR_REWRITE_PASSWORD: "",
          USR_PASSWORD: "",
        },
      });
      const { token } = this.props.match.params;
      const auth = await signIn({
        USR_PASSWORD,
        token,
      });
      if (auth) {
        await setTimeout(() => {
          document.location.href = "/";
        }, 3000);
        await this.setState({ success: true });
      }
    }
    event.preventDefault();
  }

  async componentWillMount() {
    await this.setState({ userExist: false });
    const { token } = this.props.match.params;
    const user = await checkToken(token);
    if (user) {
      await this.setState({ userExist: true });
    }
    await this.setState({ loading: true });
  }

  render() {
    const {
      success,
      loading,
      USR_REWRITE_PASSWORD,
      USR_PASSWORD,
      errors,
      userExist,
    } = this.state;
    return (
      <>
        {loading ? (
          <>
            <div
              class="login login-4 d-flex flex-row-fluid login-signin-on"
              id="kt_login"
              style={{
                backgroundColor: "black",
                backgroundImage:
                  "url(assets/media/svg/illustrations/working.svg)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            >
              <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "18px",
                    border: "12px solid black",
                    boxShadow: "1px 1px 15px white",
                  }}
                  class="login-form text-center p-7 position-relative overflow-hidden"
                >
                  {userExist ? (
                    <>
                      <div class="d-flex flex-center mb-15">
                        <span>
                          <img
                            src="assets/media/logos/k2m_logo.jpeg"
                            class=""
                            alt=""
                          />
                        </span>
                      </div>
                      {!success ? (
                        <div class="login-signin">
                          <div class="mb-20">
                            <h3>Finalisez votre inscrition</h3>
                            <div class="text-muted font-weight-bold">
                              Créer votre mot de passe et connectez vous à votre
                              application :
                            </div>
                          </div>
                          <div class="form-group mb-5 fv-plugins-icon-container">
                            <input
                              class="form-control h-auto form-control-solid py-4 px-8"
                              type="password"
                              placeholder="Nouveau mot de passe"
                              name="USR_PASSWORD"
                              onChange={this.handleChange}
                              defaultValue={USR_PASSWORD}
                            />
                            <span
                              class="text-hover-primary"
                              style={{ color: "red" }}
                            >
                              {errors.USR_PASSWORD}
                            </span>
                            <div class="fv-plugins-message-container"></div>
                          </div>
                          <div class="form-group mb-5 fv-plugins-icon-container">
                            <input
                              class="form-control h-auto form-control-solid py-4 px-8"
                              type="password"
                              placeholder="Confirmez le mot de passe"
                              name="USR_REWRITE_PASSWORD"
                              onChange={this.handleChange}
                              defaultValue={USR_REWRITE_PASSWORD}
                              z
                            />
                            <span
                              class="text-hover-primary"
                              style={{ color: "red" }}
                            >
                              {errors.USR_REWRITE_PASSWORD}
                            </span>
                            <div class="fv-plugins-message-container"></div>
                          </div>
                          <button
                            class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            onClick={this.handleSubmit}
                            style={{ backgroundColor: "black" }}
                          >
                            Enregistrer
                          </button>
                          <input type="hidden" />
                          <div></div>
                        </div>
                      ) : (
                        <div>
                          <h3>Inscrition réussie !</h3>
                          <div class="text-muted font-weight-bold">
                            Vous redirigez vers la page d'acceuil.
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <h3>Lien expiré, contactez votre Manager.</h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <ReactBootStrap.Spinner animation="border" />
          </>
        )}
      </>
    );
  }
}

export default NewPassword;
