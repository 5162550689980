"use strict";
import React, { Component } from "react";
import ButtonReturn from "../utils/button/ButtonReturn";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import InfoNav from "./InfoComposants/InfoNav";
import Content from "./InfoComposants/Content";

export class InfoFournisseur extends Component {
  render() {
    const { guid } = this.props.match.params;
    return (
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <ButtonPageTitle title="CABINETS" />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <ButtonReturn fullname={"RETOUR"} ressource="fournisseur" />
            <InfoNav />
            <Content guid={guid} />
          </div>
        </div>
      </div>
    );
  }
}

export default InfoFournisseur;
