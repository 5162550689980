import { authentification } from "./auth.dao";
import { deleteSession } from "../session/session.controller";
import { signInWithPassword, checkNewUserConnect } from "./auth.dao";
/**
 *
 * @param {*} USR_EMAIL
 * @param {*} USR_PASSWORD
 * @param {*} remember
 * @returns {Object{data, status}}
 */
async function findByEmailPasswork(USR_EMAIL, USR_PASSWORD, remember = false) {
  try {
    const response = await authentification(USR_EMAIL, USR_PASSWORD);
    const { status, data } = response;
    if (status === 200) {
      remember
        ? localStorage.setItem("tokenCrm", data.SES_GUID)
        : sessionStorage.setItem("tokenCrm", data.SES_GUID);
    }
    return response;
  } catch (error) {
    return error.response;
  }
}
/**
 *
 * @returns
 */
async function disconnect() {
  return await deleteSession();
}

/**
 *
 * @param {*} param0
 * @returns
 */
function validator({ USR_EMAIL, USR_PASSWORD }) {
  let errors = {
    exist: false,
    message: {
      USR_EMAIL: "",
      USR_PASSWORD: "",
    },
  };
  if (!USR_EMAIL) {
    errors.exist = true;
    errors.message.USR_EMAIL = "Email obligatoire";
  } else if (!/\S+@\S+\.\S+/.test(USR_EMAIL)) {
    errors.exist = true;
    errors.message.USR_EMAIL = "Adresse Email invalide";
  }
  if (!USR_PASSWORD) {
    errors.exist = true;
    errors.message.USR_PASSWORD = "Mot de passe obligatoire";
  }
  return errors;
}

function validatorSignIn({ USR_REWRITE_PASSWORD, USR_PASSWORD }) {
  let errors = {
    exist: false,
    message: {
      USR_REWRITE_PASSWORD: "",
      USR_PASSWORD: "",
    },
  };
  if (!USR_PASSWORD) {
    errors.exist = true;
    errors.message.USR_PASSWORD = "Saissisez un mot de passe";
  } else if (USR_PASSWORD.length < 6) {
    errors.exist = true;
    errors.message.USR_PASSWORD =
      "Votre mot de passe doit comporter plus de 6 caractères.";
  } else if (USR_PASSWORD !== USR_REWRITE_PASSWORD) {
    errors.exist = true;
    errors.message.USR_REWRITE_PASSWORD = "Mot de passe non conforme";
  }
  return errors;
}
/**
 *
 * @param {*} data
 * @returns
 */
async function signIn(data) {
  try {
    const result = await signInWithPassword(data);
    await deleteSession();
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 *
 * @param {*} token
 */
async function checkToken(token) {
  try {
    const result = await checkNewUserConnect(token);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export default {};
export {
  disconnect,
  findByEmailPasswork,
  validator,
  validatorSignIn,
  signIn,
  checkToken,
};
