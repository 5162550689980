import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  findAll,
  filterBy,
  searchListCon,
  trierBy,
} from "../../controller/contact.controller";
import * as ReactBootStrap from "react-bootstrap";

export class ContactsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactsFilter: [],
      contacts: [],
      filter: "",
      loading: false,
      valueSearch: "",
      valueDateBegin: "",
      valueDateEnd: "",
      trier: "",
      field: "CON_NAME",
    };
    this.inputChangeFilter = this.inputChangeFilter.bind(this);
    this.inputChangeSearch = this.inputChangeSearch.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.inputChangeTrier = this.inputChangeTrier.bind(this);
  }
  inputChangeFilter = input => async e => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    await this.setState({ loading: false });
    let c = [];
    const { filter } = this.state;
    const contacts = await findAll();
    if (filter) {
      const c = await filterBy(filter, contacts);
      await this.setState({
        contactsFilter: c,
      });
      await this.setState({
        contacts: c,
      });
    } else {
      await this.setState({
        contactsFilter: contacts,
      });
      await this.setState({
        contacts: contacts,
      });
    }
    await this.setState({ loading: true });
  };
  inputChangeTrier = input => async e => {
    await this.setState({ loading: false });
    await this.setState({
      [e.target.name]: e.target.value,
    });

    const { trier, contactsFilter } = this.state;

    if (trier) {
      await this.setState({
        contacts: await trierBy(trier, contactsFilter),
      });
    } else {
      await this.setState({
        contacts: contactsFilter,
      });
    }
    await this.setState({ trier: "" });
    await this.setState({ loading: true });
  };
  inputSearch = field => {
    switch (field) {
      case "CON_RDV_TAKEN_ON_INITIAL":
        return (
          <>
            <input
              type="date"
              class="form-control form-control-solid form-control-lg"
              name="valueDateBegin"
              onChange={this.inputChangeSearch()}
              defaultValue={Date()}
            />

            <input
              type="date"
              class="form-control form-control-solid form-control-lg"
              name="valueDateEnd"
              onChange={this.inputChangeSearch()}
              defaultValue={Date()}
            />
          </>
        );
      case "CON_DATE_RDV_INITIAL":
        return (
          <>
            <input
              type="date"
              class="form-control form-control-solid form-control-lg"
              name="valueDateBegin"
              onChange={this.inputChangeSearch()}
              defaultValue={Date()}
            />

            <input
              type="date"
              class="form-control form-control-solid form-control-lg"
              name="valueDateEnd"
              onChange={this.inputChangeSearch()}
              defaultValue={Date()}
            />
          </>
        );
      case "CON_CREATED":
        return (
          <>
            <input
              type="date"
              class="form-control form-control-solid form-control-lg"
              name="valueDateBegin"
              onChange={this.inputChangeSearch()}
              defaultValue={Date()}
            />

            <input
              type="date"
              class="form-control form-control-solid form-control-lg"
              name="valueDateEnd"
              onChange={this.inputChangeSearch()}
              defaultValue={Date()}
            />
          </>
        );
      case "CON_ZIP_CODE":
        return (
          <input
            type="number"
            class="form-control form-control-solid form-control-lg"
            name="valueSearch"
            placeholder="Rechercher"
            onChange={this.inputChangeSearch()}
          />
        );
      default:
        return (
          <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            name="valueSearch"
            placeholder="Rechercher"
            onChange={this.inputChangeSearch()}
          />
        );
    }
  };
  inputChangeSearch = input => e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeSearch = async () => {
    await this.setState({ loading: false });
    const { valueSearch, field, valueDateBegin, valueDateEnd, contactsFilter } =
      this.state;
    if (valueSearch || valueDateBegin || valueDateEnd) {
      const res = await searchListCon(
        contactsFilter,
        field,
        valueSearch,
        valueDateBegin,
        valueDateEnd
      );
      await this.setState({ contacts: res });
      await this.setState({ contactsFilter: res });
    } else {
      await this.setState({ contacts: contactsFilter });
    }
    this.setState({ loading: true });
  };
  async componentWillMount() {
    const contacts = await findAll();
    if (contacts.length) {
      await this.setState({ contacts: contacts });
      await this.setState({ contactsFilter: contacts });
    }
    this.setState({ loading: true });
  }
  render() {
    const { contacts, loading, field } = this.state;
    return (
      <div class="card card-custom gutter-b">
        <div class="card-header border-0 py-5">
          <div class="card-toolbar">
            <div class="dropdown dropdown-inline mr-2">
              <select
                style={{
                  backgroundColor: "rgb(218 8 6 / 81%)",
                  color: "white",
                  fontSize: "initial",
                }}
                class="form-control"
                id="exampleSelectd"
                name="filter"
                onChange={this.inputChangeFilter()}
                defaultValue="filter"
              >
                <option value=""> Filter sur : </option>
                <option value=""> Contacts </option>
                <option value="CLIENT"> RDV Validé </option>
                <option value="PROSPECT"> RDV Pris </option>
              </select>
            </div>
          </div>
          <div class="card-toolbar">
            <div class="dropdown dropdown-inline mr-2">
              <select
                style={{
                  backgroundColor: "rgb(218 8 6 / 81%)",
                  color: "white",
                  fontSize: "initial",
                }}
                class="form-control"
                id="exampleSelectd"
                name="trier"
                onChange={this.inputChangeTrier()}
              >
                <option value=""> Trier par : </option>
                <option value="CON_RDV_TAKEN_ON_INITIAL"> RDV PRIS LE </option>
                <option value="CON_DATE_RDV_INITIAL"> DATE DU RDV </option>
                <option value="GESTIONNAIRE"> GESTIONNAIRE </option>
              </select>
            </div>
          </div>
          <div class="card-toolbar">
            <div class="card-toolbar">
              <div class="dropdown dropdown-inline mr-2">
                <select
                  style={{
                    backgroundColor: "rgb(218 8 6 / 81%)",
                    color: "white",
                    fontSize: "initial",
                  }}
                  class="form-control"
                  id="exampleSelectd"
                  name="field"
                  onChange={this.inputChangeSearch()}
                  defaultValue="CON_FIRSTNAME"
                >
                  <option value="CON_NAME"> Contact </option>
                  <option value="CON_EMAIL"> Email </option>
                  <option value="FOU_NAME"> Cabinet </option>
                  <option value="CON_PROJECT"> Projet </option>
                  <option value="CON_ISSU_RDV"> Issu du rdv </option>
                  <option value="CON_PLACE_RDV"> Lieu du rdv </option>
                  <option value="CON_RDV_TAKEN_ON_INITIAL">
                    RDV pris entre
                  </option>
                  <option value="CON_DATE_RDV_INITIAL"> Date du RDV </option>
                  <option value="CON_CREATED"> Date d'ajout </option>
                  <option value="GESTIONNAIRE"> Gestionnaire </option>
                  <option value="CON_ZIP_CODE"> Code postal </option>
                </select>
              </div>
            </div>
            <div
              class="dropdown dropdown-inline mr-2"
              style={{
                display: "inline-flex",
              }}
            >
              {this.inputSearch(field)}
              <div class="input-group-prepend">
                <button
                  class="input-group-text"
                  data-children-count="0"
                  onClick={this.handleChangeSearch}
                >
                  <i
                    style={{ color: "rgba(218, 8, 6, 0.81)" }}
                    class="la la-search"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="table-responsive" style={{ maxHeight: "700px" }}>
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0 tb-id" id="tb-id">
                    id
                  </th>
                  <th className="tb-name">
                    <span class="text-primary">Prénom Nom</span>
                  </th>
                  <th className="tb-others">
                    <span class="text-primary">RDV pris le:</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Date RDV</span>
                  </th>
                  <th className="tb-others">
                    <span class="text-primary">Lieu du RDV</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Cabinet</span>
                  </th>
                  <th className="tb-name">
                    <span class="text-primary">Gestionnaire</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Type de projet</span>
                  </th>
                  <th className="tb-others">
                    <span class="text-primary">Code postal</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">L’issu du RDV</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  contacts.length ? (
                    contacts.map((c, i) => {
                      return (
                        <>
                          <tr>
                            <td class="pl-0">
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {i + 1}
                              </span>
                            </td>
                            <td>
                              <Link
                                to={`/contacts/${c.CON_GUID}`}
                                class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                              >
                                {c.CON_NAME}
                              </Link>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg ">
                                {c.CON_RDV_TAKEN_ON}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg red-colon">
                                {c.CON_DATE_RDV}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {c.CON_PLACE_RDV}
                              </span>
                            </td>
                            <td>
                              <Link
                                to={
                                  c.CON_FOURNISSEUR_GUID &&
                                  `/fournisseurs/${c.CON_FOURNISSEUR_GUID}`
                                }
                                class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                              >
                                {c.CON_FOURNISSEUR_LABEL ||
                                  c.CON_FOURNISSEUR_NAME}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/employes/${c.GES_USR_GUID}`}
                                class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                              >
                                {c.GESTIONNAIRE}
                              </Link>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {c.CON_PROJECT}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {c.CON_ZIP_CODE}
                              </span>
                            </td>
                            <td>
                              <span class="label label-lg label-light-primary label-inline">
                                {c.CON_ISSU_RDV}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                          marginTop: "30px",
                        }}
                      >
                        <Link
                          to="/ajouter-contact"
                          class="btn btn-success font-weight-bolder font-size-sm"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  opacity="0.3"
                                ></path>
                                <path
                                  d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                ></path>
                              </g>
                            </svg>
                          </span>
                          Ajouter un nouveau contact
                        </Link>
                      </div>
                      <div style={{ marginBottom: "100px" }}></div>
                    </>
                  )
                ) : (
                  <ReactBootStrap.Spinner animation="border" />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactsList;
