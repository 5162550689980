import API from "../../../utils/api/api";

const UpdateFournisseur = async (
  {
    FOU_NAME,
    FOU_ZONE,
    FOU_ZIP_CODE,
    FOU_ADDRESS,
    FOU_COUNTRY,
    FOU_EMAIL,
    FOU_PHONE,
  },
  FOU_GUID
) => {
  try {
    const response = await API.post("adm_fou.php", {
      token: "22392",
      ajaxAction: "update",
      FOU_GUID: FOU_GUID,
      ...(FOU_NAME ? { FOU_NAME } : {}),
      ...(FOU_ZONE ? { FOU_ZONE } : {}),
      ...(FOU_ZIP_CODE ? { FOU_ZIP_CODE } : {}),
      ...(FOU_ADDRESS ? { FOU_ADDRESS } : {}),
      ...(FOU_COUNTRY ? { FOU_COUNTRY } : {}),
      ...(FOU_EMAIL ? { FOU_EMAIL } : {}),
      ...(FOU_PHONE ? { FOU_PHONE } : {}),
    });
    console.log(response);
    if (response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    return false;
  }
};
export default UpdateFournisseur;
