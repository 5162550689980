import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false,
      activeBirthday: false,
    };
  }

  render() {
    const { contacts } = this.props.data;

    return (
      <div class="card card-custom gutter-b">
        <div class="card-header border-0 py-5">
          <div class="card-toolbar">
            <div class="dropdown dropdown-inline mr-2">
              <button
                type="button"
                class="btn btn-light-primary font-weight-bolder dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="svg-icon svg-icon-md"></span>Filtrer sur :
              </button>
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-left">
                <ul class="navi flex-column navi-hover py-2">
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-text">Clients</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-text">Prospects</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-text">Date de RDV</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0 tb-id" id="tb-id">
                    id
                  </th>
                  <th className="tb-name">
                    <span class="text-primary">Prénom Nom</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Date RDV</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">RDV pris le:</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Lieu du RDV</span>
                  </th>
                  <th className="tb-name">
                    <span class="text-primary">Ajouter par</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Type de projet</span>
                  </th>
                  <th className="tb-others">
                    <span class="text-primary">Code postal</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">L’issu du RDV</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {contacts.length ? (
                  contacts.map((c, i) => {
                    return (
                      <>
                        <tr>
                          <td class="pl-0">
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {i + 1}
                            </span>
                          </td>
                          <td>
                            <Link
                              to={`/contacts/${c.CON_GUID}`}
                              class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                            >
                              {c.CON_NAME}
                            </Link>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {c.CON_DATE_RDV}
                            </span>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {c.CON_RDV_TAKEN_ON}
                            </span>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {c.CON_PLACE_RDV}
                            </span>
                          </td>
                          <td>
                            <Link
                              to={`/employes/${c.CON_CREATED_BY}`}
                              class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                            >
                              {c.CON_USER_CREATED_NAME}
                            </Link>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {c.CON_PROJECT}
                            </span>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {c.CON_ZIP_CODE}
                            </span>
                          </td>
                          <td>
                            <span class="label label-lg label-light-primary label-inline">
                              {c.CON_ISSU_RDV}
                            </span>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        marginTop: "30px",
                      }}
                    >
                      <Link
                        to="/ajouter-contact"
                        class="btn btn-success font-weight-bolder font-size-sm"
                      >
                        <span class="svg-icon svg-icon-md svg-icon-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24"></polygon>
                              <path
                                d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                              ></path>
                              <path
                                d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        Ajouter un nouveau contact
                      </Link>
                    </div>
                    <div style={{ marginBottom: "100px" }}></div>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
