import React, { Component } from "react";
import { findManagersByGuid } from "../../controller/contact.controller";
import * as ReactBootStrap from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";

export class Gestionnaires extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
    };
  }
  async componentWillMount() {
    const { guid } = this.props;
    const users = await findManagersByGuid(guid);
    if (users.length) {
      await this.setState({ users: users });
    }
    await this.setState({ loading: true });
  }
  render() {
    const { loading, users } = this.state;
    return (
      <div class="tab-pane px-7" id="kt_user_edit_tab_2" role="tabpanel">
        <div class="card card-custom gutter-b">
          <div class="card-body py-0">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_2"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th class="pl-0" style={{ minWidth: "50px" }}>
                      {" "}
                      id
                    </th>
                    <th style={{ minWidth: "180px" }}>
                      <span class="text-primary">Prénom Nom</span>
                    </th>
                    <th style={{ minWidth: "150px" }}>
                      <span class="text-primary">Date d'affectation</span>
                    </th>
                    <th style={{ minWidth: "150px" }}>
                      <span class="text-primary">Créateur du contact</span>
                    </th>
                    <th style={{ minWidth: "150px" }}>
                      <span class="text-primary">Rôle</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    users.map((user, index) => (
                      <tr>
                        <td class="pl-0">
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {index + 1}
                          </span>
                        </td>
                        <td>
                          <Link
                            to={`/employes/${user.USR_GUID}`}
                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                          >
                            {`${user.USR_FIRSTNAME || ""} ${
                              user.USR_LASTNAME || ""
                            }`}
                          </Link>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {moment(user.AUT_CREATED).format("DD/MM/YYYY")}
                          </span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {user.USR_GUID === user.CON_CREATED_BY
                              ? `Oui`
                              : `Non`}
                          </span>
                        </td>
                        <td>
                          <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {user.USR_ROLE}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <ReactBootStrap.Spinner animation="border" />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gestionnaires;
