import React, { Component } from "react";
import { OPTION } from "../controller/lead.const";
import Select from "react-select";

export class Step1 extends Component {
  render() {
    const errors = false;
    return (
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <form
          action="https://efficy.groupe-quintesens.fr/crm/guest"
          method="GET"
        >
          <h3 class="mb-10 font-weight-bold text-dark">Informations LEAD</h3>
          <div style={{ display: "none" }}>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="app"
              value="leadprod"
            />
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="page"
              value="CreateLead.htm"
            />
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="Plateforme"
              value="k2mdeveloppement"
            />
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Prénom</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="FName"
                  //onChange=={inputChange("FName")}
                  //defaultValue={values.FName}
                />
                <span class="form-text red-colon">
                  {errors && errors.FName}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="LName"
                  //onChange=={inputChange("LName")}
                  placeholder="Maisonnave"
                  //defaultValue={values.LName}
                />
                <span class="form-text red-colon">
                  {errors && errors.LName}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <label>Adresse</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="Adresse"
                  //onChange=={inputChange("Adresse")}
                  //defaultValue={values.Adresse}
                />
                <span class="form-text red-colon">
                  {errors && errors.Adresse}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Code Postal</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="CP"
                  //onChange=={inputChange("CP")}
                  //defaultValue={values.CP}
                />
                <span class="form-text red-colon">{errors && errors.CP}</span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Téléphone</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="Phone1"
                  //onChange=={inputChange("Phone1")}
                  placeholder="0607849394"
                  //defaultValue={values.Phone1}
                />
                <span class="form-text red-colon">
                  {errors && errors.Phone1}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Adresse Email</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="Email1"
                  //onChange=={inputChange("Email1")}
                  //defaultValue={values.Email1}
                />
                <span class="form-text red-colon">
                  {errors && errors.Email1}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Imposition</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="Imposition"
                  //onChange=={inputChange("Imposition")}
                  //defaultValue={values.Imposition}
                />
                <span class="form-text red-colon">
                  {errors && errors.Imposition}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Financement</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="financement"
                  //onChange=={inputChange("financement")}
                  //defaultValue={values.financement}
                />
                <span class="form-text red-colon">
                  {errors && errors.financement}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Revenu</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="revenu"
                  //onChange=={inputChange("revenu")}
                  //defaultValue={values.revenu}
                />
                <span class="form-text red-colon">
                  {errors && errors.revenu}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Date de naissance</label>
                <input
                  type="date"
                  class="form-control form-control-solid form-control-lg"
                  name="DateNaissance"
                  //onChange=={inputChange("DateNaissance")}
                  //defaultValue={values.DateNaissance}
                />
                <span class="form-text red-colon">
                  {errors && errors.DateNaissance}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Situation Matrimoniale</label>
                <Select
                  name="situmat"
                  options={OPTION.OPTION_MATRIMONIALE}
                  defaultValue={{
                    value: "",
                    label: "",
                  }}
                  //onChange=={inputChange("situmat")}
                />
                <span class="form-text red-colon">
                  {errors && errors.situmat}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <label>Placement (Capacité De Financement)</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="placement"
                  //onChange=={inputChange("placement")}
                  //defaultValue={values.placement}
                />
                <span class="form-text red-colon">
                  {errors && errors.placement}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-9">
              <div class="form-group">
                <label>Identifiant Unique Du Lead</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="Identifiant"
                  //onChange=={inputChange("Identifiant")}
                  //defaultValue={values.Identifiant}
                />
                <span class="form-text red-colon">
                  {errors && errors.Identifiant}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Profession</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="situpro"
                  //onChange=={inputChange("situpro")}
                  //defaultValue={values.situpro}
                />
                <span class="form-text red-colon">
                  {errors && errors.situpro}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Propriétaire Ou Locataire</label>
                <Select
                  name="proploc"
                  options={OPTION.OPTION_PROPRIETAIRE}
                  defaultValue={{
                    value: "",
                    label: "",
                  }}
                  //onChange=={inputChange("proploc")}
                />
                <span class="form-text red-colon">
                  {errors && errors.proploc}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Objectif</label>
                <Select
                  name="objectif"
                  options={OPTION.OPTION_OBJECTIF}
                  defaultValue={{
                    value: "",
                    label: "",
                  }}
                  //onChange=={inputChange("objectif")}
                />
                <span class="form-text red-colon">
                  {errors && errors.objectif}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Nombre D'enfants</label>
                <input
                  type="number"
                  class="form-control form-control-solid form-control-lg"
                  name="enfant"
                  //onChange=={inputChange("enfant")}
                  //defaultValue={values.enfant}
                />
                <span class="form-text red-colon">
                  {errors && errors.enfant}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Nombre De Personne Dans Le Foyer</label>
                <input
                  type="number"
                  class="form-control form-control-solid form-control-lg"
                  name="foyer"
                  //onChange=={inputChange("foyer")}
                  //defaultValue={values.foyer}
                />
                <span class="form-text red-colon">
                  {errors && errors.foyer}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Nombre D'enfants A Charge</label>
                <input
                  type="number"
                  class="form-control form-control-solid form-control-lg"
                  name="enfantacharge"
                  //onChange=={inputChange("enfantacharge")}
                  //defaultValue={values.enfantacharge}
                />
                <span class="form-text red-colon">
                  {errors && errors.enfantacharge}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between border-top mt-5 pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                type="submit"
                formTarget="_blank"
              >
                ENVOYER
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Step1;
