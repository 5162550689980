import API from "../../utils/api/api";
const ListFournisseur = async values => {
  const val = { token: "22392", ajaxAction: "list" };

  try {
    const response = await API.post("adm_fou.php", val);
    return { ...response };
  } catch (error) {
    return false;
  }
};
export default ListFournisseur;
