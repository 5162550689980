"use strict";
import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div class="brand flex-column-auto" id="kt_brand">
      <Link to="/" class="brand-logo">
        <img alt="Logo" class="w-65px" src="assets/media/logos/k2m_logo.jpeg" />
      </Link>
    </div>
  );
};

export default Logo;
