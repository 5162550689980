import React, { Component } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import validateFournisseur from "../controller/validateFournisseur";
import AddFournisseur from "../controller/model/AddFournisseur";

export class Steps extends Component {
  state = {
    step: 1,
    FOU_NAME: "",
    FOU_ZONE: "",
    FOU_ZIP_CODE: "",
    FOU_ADDRESS: "",
    FOU_COUNTRY: "",
    FOU_EMAIL: "",
    FOU_PHONE: "",
    errors: {},
    results: "",
  };

  nextStep = async () => {
    const { step, ...values } = this.state;
    const errors = validateFournisseur(values);
    this.setState({ errors });
    if (!errors) {
      await this.setState({ step: step + 1 });
      this.props.steps(step);
    }
    if (this.state.step == 3) {
      const res = await AddFournisseur(values);
      await this.setState({ results: res });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  inputChange = input => e => {
    this.setState({
      [input]: e.target.value,
    });
  };

  render() {
    const { step } = this.state;
    const {
      FOU_NAME,
      FOU_ZONE,
      FOU_ZIP_CODE,
      FOU_ADDRESS,
      FOU_EMAIL,
      FOU_COUNTRY,
      FOU_PHONE,
      errors,
      results,
    } = this.state;
    const values = {
      FOU_NAME,
      FOU_ZONE,
      FOU_ZIP_CODE,
      FOU_ADDRESS,
      FOU_COUNTRY,
      FOU_EMAIL,
      FOU_PHONE,
      errors,
    };

    switch (step) {
      case 1:
        return (
          <Step1
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={values}
          />
        );
      case 3:
        return <Step3 values={results} />;
    }
  }
}
export default Steps;
