import React, { Component } from "react";
import Commentaires from "./Commentaires";
import Historiques from "./Historiques";
import { OPTION } from "../../controller/contact.const";
import Select from "react-select";
import { validatorUpdate } from "../../controller/contact.validator";
import { ROLE } from "../../../employes/controller/employee.const";
export class DetailsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false,
      activeRdvPrisLe: false,
      activeDateRdv: false,
    };
  }
  inputChange = input => async e => {
    const {
      data: { contact },
    } = this.props;
    if (e.value || e.value === 0) {
      this.setState({
        [input]: e.value,
      });
    } else if (e.target.value || e.target.value === 0) {
      this.setState({
        [input]: e.target.value,
      });
    } else {
    }
    const { exist } = validatorUpdate(this.state, contact);
    if (exist) {
      this.setState({ change: true });
    } else {
      this.setState({ change: false });
    }
  };

  activeChangeDate = input => async e => {
    const { activeDateRdv, activeRdvPrisLe } = this.state;
    if (input === "CON_DATE_RDV") {
      this.setState({ activeDateRdv: !activeDateRdv });
    }
    if (input === "CON_RDV_TAKEN_ON") {
      this.setState({ activeRdvPrisLe: !activeRdvPrisLe });
    }
  };
  handleClick = async () => {
    const { data, canUpdate } = this.props;
    const { contact } = data;
    const { change, activeRdvPrisLe, activeDateRdv, ...payload } = this.state;
    if (payload.CON_ISSU_RDV) {
      if (payload.CON_ISSU_RDV == "VALIDE") {
        payload.CON_STATUS = "CLIENT";
      } else {
        payload.CON_STATUS = "PROSPECT";
      }
    }
    const changeExist = validatorUpdate(this.state, contact);
    await canUpdate(payload, changeExist);
  };
  render() {
    const { contact, error } = this.props.data;
    const { activeRdvPrisLe, activeDateRdv, activeBirthday } = this.state;
    return (
      <div
        class="tab-pane show active px-7"
        id="kt_user_edit_tab_1"
        role="tabpanel"
      >
        <div class="row ">
          <div class="col-xl-7">
            <div class="row">
              <label class="col-3"></label>
              <div class="col-9">
                <h6 class="text-dark font-weight-bold mb-10">Fiche de RDV:</h6>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Prénom
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_FIRSTNAME}
                  onChange={this.inputChange("CON_FIRSTNAME")}
                  name="CON_FIRSTNAME"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Nom
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_LASTNAME}
                  onChange={this.inputChange("CON_LASTNAME")}
                  name="CON_LASTNAME"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Téléphone
              </label>
              <div class="col-9">
                <div class="input-group input-group-lg ">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-phone"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    defaultValue={contact.CON_PHONE}
                    onChange={this.inputChange("CON_PHONE")}
                    name="CON_PHONE"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Adresse email
              </label>
              <div class="col-9">
                <div class="input-group input-group-lg ">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-at"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    defaultValue={contact.CON_EMAIL}
                    onChange={this.inputChange("CON_EMAIL")}
                    name="CON_EMAIL"
                  />
                </div>
                <span class="form-text text-muted">
                  {error && error.CON_EMAIL}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Profession
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_JOB}
                  onChange={this.inputChange("CON_JOB")}
                  name="CON_JOB"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Âge
              </label>
              <div class="col-6">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="number"
                  defaultValue={contact.CON_AGE}
                  onChange={this.inputChange("CON_AGE")}
                  name="CON_AGE"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Nombre d'enfant à charge
              </label>
              <div class="col-9">
                <Select
                  name="CON_CHILD_COUNT"
                  options={OPTION.OPTION_CHILD_COUNT}
                  defaultValue={{
                    value: contact.CON_CHILD_COUNT,
                    label: contact.CON_CHILD_COUNT,
                  }}
                  onChange={this.inputChange("CON_CHILD_COUNT")}
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Nombre de personne dans le foyer
              </label>
              <div class="col-9">
                <Select
                  name="CON_COUNT_PEOPLE_IN_HOUSEHOLD"
                  options={OPTION.OPTION_COUNT_PEOPLE_IN_HOUSEHOLD}
                  defaultValue={{
                    value: contact.CON_COUNT_PEOPLE_IN_HOUSEHOLD,
                    label: contact.CON_COUNT_PEOPLE_IN_HOUSEHOLD,
                  }}
                  onChange={this.inputChange("CON_COUNT_PEOPLE_IN_HOUSEHOLD")}
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Statut matrimonial
              </label>
              <div class="col-9">
                <Select
                  name="CON_MARITAL_STATUS"
                  options={OPTION.OPTION_MARITAL_STATUS}
                  defaultValue={{
                    value: contact.CON_MARITAL_STATUS,
                    label: contact.CON_MARITAL_STATUS,
                  }}
                  onChange={this.inputChange("CON_MARITAL_STATUS")}
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Statut (Locataire, ...)
              </label>
              <div class="col-9">
                <Select
                  name="CON_STATUS_HOUSE"
                  options={OPTION.OPTION_STATUS_HOUSE}
                  defaultValue={{
                    value: contact.CON_STATUS_HOUSE,
                    label: contact.CON_STATUS_HOUSE,
                  }}
                  onChange={this.inputChange("CON_STATUS_HOUSE")}
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Projet
              </label>
              <div class="col-9">
                <Select
                  name="CON_PROJECT"
                  options={OPTION.OPTION_PROJECT}
                  defaultValue={{
                    value: contact.CON_PROJECT,
                    label: contact.CON_PROJECT,
                  }}
                  onChange={this.inputChange("CON_PROJECT")}
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Adresse
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_ADDRESS}
                  onChange={this.inputChange("CON_ADDRESS")}
                  name="CON_ADDRESS"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Ville
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_CITY}
                  onChange={this.inputChange("CON_CITY")}
                  name="CON_CITY"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Code postal
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_ZIP_CODE}
                  onChange={this.inputChange("CON_ZIP_CODE")}
                  name="CON_ZIP_CODE"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Montant de l'impôt
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_IMPOT}
                  onChange={this.inputChange("CON_IMPOT")}
                  name="CON_IMPOT"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Revenu fiscal de référence
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_REVENU_FISCAL_REFERENCE}
                  onChange={this.inputChange("CON_REVENU_FISCAL_REFERENCE")}
                  name="CON_REVENU_FISCAL_REFERENCE"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Revenu foncier
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_REVENU_FONCIER}
                  onChange={this.inputChange("CON_REVENU_FONCIER")}
                  name="CON_REVENU_FONCIER"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Crédit automobile
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_CREDIT_CAR}
                  onChange={this.inputChange("CON_CREDIT_CAR")}
                  name="CON_CREDIT_CAR"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Crédit conso
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_CREDIT_CONSO}
                  onChange={this.inputChange("CON_CREDIT_CONSO")}
                  name="CON_CREDIT_CONSO"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Crédit immobilier
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_CREDIT_IMMO}
                  onChange={this.inputChange("CON_CREDIT_IMMO")}
                  name="CON_CREDIT_IMMO"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Autres crédits
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_CREDIT_OTHER}
                  onChange={this.inputChange("CON_CREDIT_OTHER")}
                  name="CON_CREDIT_OTHER"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Montant loyer
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  defaultValue={contact.CON_MONTANT_LOYER}
                  onChange={this.inputChange("CON_MONTANT_LOYER")}
                  name="CON_MONTANT_LOYER"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Rdv pris le
              </label>
              <div class="col-6">
                {activeRdvPrisLe ? (
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="date"
                    defaultValue={contact.CON_RDV_TAKEN_ON}
                    onChange={this.inputChange("CON_RDV_TAKEN_ON")}
                    name="CON_RDV_TAKEN_ON"
                  />
                ) : (
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    value={contact.CON_RDV_TAKEN_ON}
                  />
                )}
              </div>
              <button
                style={{
                  background: "white",
                  borderRadius: "5px",
                }}
                onClick={this.activeChangeDate("CON_RDV_TAKEN_ON")}
              >
                <span class="mr-4">
                  <i class="flaticon-edit display-4 text-muted font-weight-bold"></i>
                </span>
              </button>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Lieu du rdv
              </label>
              <div class="col-9">
                <Select
                  name="CON_PLACE_RDV"
                  options={OPTION.OPTION_PLACE_RDV}
                  defaultValue={{
                    value: contact.CON_PLACE_RDV,
                    label: contact.CON_PLACE_RDV,
                  }}
                  onChange={this.inputChange("CON_PLACE_RDV")}
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Date du rdv
              </label>
              <div class="col-6">
                {activeDateRdv ? (
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="datetime-local"
                    defaultValue={contact.CON_DATE_RDV}
                    placeholder={contact.CON_DATE_RDV}
                    onChange={this.inputChange("CON_DATE_RDV")}
                    name="CON_DATE_RDV"
                  />
                ) : (
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    value={contact.CON_DATE_RDV}
                    placeholder={contact.CON_DATE_RDV}
                    name="CON_DATE_RDV"
                  />
                )}
              </div>
              <button
                style={{
                  background: "white",
                  borderRadius: "5px",
                }}
                onClick={this.activeChangeDate("CON_DATE_RDV")}
              >
                <span class="mr-4">
                  <i class="flaticon-edit display-4 text-muted font-weight-bold"></i>
                </span>
              </button>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Commentaire
              </label>
              <div class="col-9">
                <textarea
                  class="form-control form-control-lg form-control-solid"
                  id="exampleTextarea"
                  rows="3"
                  placeholder="Ajouter commentaire"
                  defaultValue={contact.CON_COMMENT}
                  onChange={this.inputChange("CON_COMMENT")}
                  name="CON_COMMENT"
                  type="text"
                ></textarea>
              </div>
            </div>
            <div class="form-group text-right">
              <div class="col-9">
                <button
                  onClick={this.handleClick}
                  class="btn btn-light-primary font-weight-bold "
                >
                  Enregister
                </button>
              </div>
            </div>
          </div>
          <div class="col-xl-5">
            <> </>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Issu du RDV
              </label>
              <div class="col-9">
                {contact.CON_USR_CONNECT_AUTH === ROLE.ADMIN ? (
                  <Select
                    name="CON_ISSU_RDV"
                    options={OPTION.OPTION_ISSU_RDV}
                    defaultValue={{
                      value: contact.CON_ISSU_RDV,
                      label: contact.CON_ISSU_RDV,
                    }}
                    onChange={this.inputChange("CON_ISSU_RDV")}
                  />
                ) : (
                  <>
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      value={contact.CON_ISSU_RDV}
                    />{" "}
                  </>
                )}
              </div>
            </div>

            {contact.CON_USR_CONNECT_AUTH === ROLE.ADMIN && (
              <>
                {" "}
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Notes direction
                  </label>
                  <div class="col-9">
                    <textarea
                      class="form-control form-control-lg form-control-solid"
                      id="exampleTextarea"
                      rows="3"
                      placeholder="Ajouter une note"
                      defaultValue={contact.CON_NOTE}
                      onChange={this.inputChange("CON_NOTE")}
                      name="CON_NOTE"
                      type="text"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group text-center">
                  <div class="col-9">
                    <button
                      onClick={this.handleClick}
                      class="btn btn-light-primary font-weight-bold "
                    >
                      Enregister
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: "#eef0f8 35px solid",
                    marginTop: "15px",
                    marginBottom: "15px",
                    position: "inherit",
                    // right: "-7%",
                    left: "-7%",
                    width: "110%",
                  }}
                ></div>
                <Commentaires guid={contact.CON_GUID} />{" "}
              </>
            )}

            <Historiques
              guid={contact.CON_GUID}
              historiques={this.props.historiques}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsInfo;
