import React from "react";

const Historiques = ({ historiques }) => {
  return (
    <div class="container" id="historique">
      <h6 class="text-dark font-weight-bold mb-10">Historiques</h6>
      {historiques.length
        ? historiques.map(h => {
            return (
              <div
                class="d-flex align-items-center"
                style={{ marginBottom: "15px" }}
              >
                <div class="symbol symbol-40 symbol-light-success mr-5">
                  <span class="symbol-label font-size-h5 font-weight-bold">
                    {(h.HIS_VARIABLE || "U").charAt(0)}
                  </span>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                  {h.HIS_VARIABLE}

                  <span class="text-muted font-weight-bold">
                    {h.HIS_CREATED}
                  </span>
                </div>
              </div>
            );
          })
        : "Vide "}
    </div>
  );
};

export default Historiques;
