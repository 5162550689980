import { getAll } from "./historique.dao";
import { historiqueItem } from "./historique.mapper";
import moment from "moment";

/**
 *
 * @returns
 */
async function findAll(objectGuid) {
  try {
    const result = await getAll(objectGuid);
    const { data } = result;
    let mapper = [];
    if (data.length) {
      for (const d of data) {
        mapper.push(historiqueItem(d));
      }
    }
    return Promise.all(mapper);
  } catch (error) {
    console.log(`findAll : ${error}`);
    return false;
  }
}

export default {};
export { findAll };
