import React, { Component } from "react";
import { OPTION } from "../controller/contact.const";
import Select from "react-select";

export class Step3 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  previous = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values = {}, inputChange } = this.props;
    const errors = values.errors || {};
    return (
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h4 class="mb-10 font-weight-bold text-dark">RDV</h4>
        <div class="form-group">
          <label>Rdv pris le</label>
          <input
            type="date"
            class="form-control form-control-solid form-control-lg"
            name="CON_RDV_TAKEN_ON"
            onChange={inputChange("CON_RDV_TAKEN_ON")}
            defaultValue={values.CON_RDV_TAKEN_ON}
          />
          <span className="review-form">
            {errors && errors.CON_RDV_TAKEN_ON}
          </span>
        </div>
        <div class="form-group">
          <label>Date du rdv</label>
          <input
            type="datetime-local"
            class="form-control form-control-solid form-control-lg"
            name="CON_DATE_RDV"
            onChange={inputChange("CON_DATE_RDV")}
            defaultValue={values.CON_DATE_RDV}
          />
          <span className="review-form">{errors && errors.CON_DATE_RDV}</span>
        </div>
        <div class="form-group">
          <label>Lieu du rdv </label>
          <Select
            name="CON_PLACE_RDV"
            options={OPTION.OPTION_PLACE_RDV}
            value={{
              value: values.CON_PLACE_RDV,
              label: values.CON_PLACE_RDV,
            }}
            onChange={inputChange("CON_PLACE_RDV")}
          />
          <span className="review-form">{errors && errors.CON_PLACE_RDV}</span>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.previous}
            >
              Précédent
            </button>
          </div>
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step3;
