import React, { Component } from "react";
import Select from "react-select";
import { OPTION } from "../../controller/employee.const";
import { validatorUpdate } from "../../controller/employee.validator";

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false,
    };
  }
  inputChange = (input) => async (e) => {
    const {
      data: { employe },
    } = this.props;
    if (e.value || e.value === 0) {
      this.setState({
        [input]: e.value,
      });
    } else if (e.target.value || e.target.value === 0) {
      this.setState({
        [input]: e.target.value,
      });
    } else {
    }

    const { exist } = validatorUpdate(this.state, employe);
    if (exist) {
      this.setState({ change: true });
    } else {
      this.setState({ change: false });
    }
  };

  handleClick = async () => {
    const { data, canUpdate } = this.props;
    const { employe } = data;
    const { change, activeBirthday, ...payload } = this.state;
    const changeExist = validatorUpdate(this.state, employe);
    await canUpdate(payload, changeExist);
  };
  render() {
    const { employe, error } = this.props.data;

    return (
      <div class="card card-custom ">
        <div class="card-body">
          <div class="tab-content">
            <div
              class="tab-pane show active px-7"
              id="kt_user_edit_tab_1"
              role="tabpanel"
            >
              <div class="row ">
                <div class="col-xl-7">
                  <div class="row">
                    <label class="col-3"></label>
                    <div class="col-9">
                      <h6 class="text-dark font-weight-bold mb-10">
                        Informations générales
                      </h6>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Droit d'accès
                    </label>
                    <div class="col-9">
                      <Select
                        name="USR_ROLE"
                        options={OPTION.OPTION_ACCESS}
                        defaultValue={{
                          value: employe.USR_ROLE,
                          label: employe.USR_ROLE,
                        }}
                        onChange={this.inputChange("USR_ROLE")}
                      />

                      <span class="form-text text-muted">{error.USR_ROLE}</span>
                    </div>
                  </div>

                  <div class="form-group text-right">
                    <div class="col-9">
                      <button
                        onClick={this.handleClick}
                        class="btn btn-light-primary font-weight-bold "
                      >
                        Enregister
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
