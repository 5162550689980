import React, { Component } from "react";
import "../../App.css";
import { findByEmailPasswork, validator } from "./auth.controller";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      USR_EMAIL: "",
      USR_PASSWORD: "",
      remember: false,
      errors: {
        USR_EMAIL: "",
        USR_PASSWORD: "",
      },
      response: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    const { USR_EMAIL, USR_PASSWORD, remember } = this.state;
    const { exist, message } = await validator(this.state);
    if (exist) {
      await this.setState({
        errors: {
          USR_EMAIL: message.USR_EMAIL,
          USR_PASSWORD: message.USR_PASSWORD,
        },
      });
    } else {
      await this.setState({
        errors: {
          USR_EMAIL: "",
          USR_PASSWORD: "",
        },
      });

      const auth = await findByEmailPasswork(USR_EMAIL, USR_PASSWORD, remember);
      if (auth.status == 200) {
        document.location.href = "/";
      } else {
        await this.setState({
          response: `L'adresse USR_EMAIL ou le mot de passe saisi(e) est incorrect(e), veuiller ressayer svp.`,
        });
      }
    }

    event.preventDefault();
  }

  render() {
    const { USR_EMAIL, USR_PASSWORD, errors, response } = this.state;
    return (
      <div
        class="login login-4 d-flex flex-row-fluid login-signin-on"
        id="kt_login"
        style={{
          backgroundColor: "#A70806",
          backgroundImage: "url(assets/media/svg/illustrations/working.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      >
        <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "18px",
              border: "12px solid #a70706",
              boxShadow: "1px 1px 15px #c15353",
            }}
            class="login-form text-center p-7 position-relative overflow-hidden"
          >
            <div class="d-flex flex-center mb-15">
              <span>
                <img src="assets/media/logos/k2m_logo.jpeg" class="" alt="" />
              </span>
            </div>
            <div class="login-signin">
              <div class="mb-20">
                <h3>Connectez-vous à votre CRM</h3>
                <div class="text-muted font-weight-bold">
                  Entrez vos identifiants pour vous connecter à votre compte :
                </div>
              </div>
              <div class="form-group mb-5 fv-plugins-icon-container">
                <h3 style={{ color: "red" }}>{response} </h3>
                <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  placeholder="Email"
                  name="USR_EMAIL"
                  onChange={this.handleChange}
                  value={USR_EMAIL}
                />
                <span class="text-hover-primary" style={{ color: "red" }}>
                  {errors.USR_EMAIL}
                </span>
                <div class="fv-plugins-message-container"></div>
              </div>
              <div class="form-group mb-5 fv-plugins-icon-container">
                <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="password"
                  placeholder="Mot de passe"
                  name="USR_PASSWORD"
                  onChange={this.handleChange}
                  value={USR_PASSWORD}
                  z
                />
                <span class="text-hover-primary" style={{ color: "red" }}>
                  {errors.USR_PASSWORD}
                </span>
                <div class="fv-plugins-message-container"></div>
              </div>
              <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-outline m-0 text-muted">
                    <input
                      type="checkbox"
                      name="remember"
                      value={true}
                      onChange={this.handleChange}
                    />
                    <span></span>Restez connecté
                  </label>
                </div>
                <a
                  href="javascript:;"
                  id="kt_login_forgot"
                  class="text-muted text-hover-primary"
                >
                  Mot de passe oublié ?
                </a>
              </div>
              <button
                class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                onClick={this.handleSubmit}
                style={{ backgroundColor: "#A70806" }}
              >
                Se connecter
              </button>
              <input type="hidden" />
              <div></div>
            </div>
          </div>
        </div>
        {/* <div
          class="content order-1 order-lg-2 d-flex flex-column w-50 pb-0"
          style={{ backgroundColor: "#A70806" }}
        >
          <div class="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
            <h3 class="login-text display4 font-weight-bolder my-7 text-dark">
              Votre CRM sur mesure
            </h3>
          </div>

          <div
            class="content-img d-flex flex-row-fluid bgi-no-repeat"
            style={{
              backgroundImage:
                "url(assets/media/svg/illustrations/working.svg)",
            }}
          ></div>
        </div> */}
      </div>
    );
  }
}

export default Login;
