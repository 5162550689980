import React, { Component } from "react";

export class Step4 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  previous = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values = {}, inputChange } = this.props;
    const errors = values.errors || {};
    return (
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h4 class="mb-10 font-weight-bold text-dark">Autres</h4>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Commentaire</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                name="CON_COMMENT"
                onChange={inputChange("CON_COMMENT")}
                placeholder="..."
                defaultValue={values.CON_COMMENT}
              ></textarea>
              <span class="form-text text-muted">
                {errors && errors.CON_COMMENT}
              </span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Note</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                name="CON_NOTE"
                onChange={inputChange("CON_NOTE")}
                placeholder="..."
                defaultValue={values.CON_NOTE}
              ></textarea>
              <span class="form-text text-muted">
                {errors && errors.CON_NOTE}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.previous}
            >
              Précédent
            </button>
          </div>
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step4;
