"use strict";
import React from "react";
import ButtonAnnuler from "../utils/button/ButtonAnnuler";
import Content from "./AddComposants/Content";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";

const AddContact = () => {
  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <ButtonPageTitle title="CONTACTS" />
      <div class="d-flex flex-column-fluid">
        <div class="container">
          <ButtonAnnuler ressource="contact" />
          <Content />
        </div>
      </div>
    </div>
  );
};

export default AddContact;
