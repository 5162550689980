import React, { Component } from "react";

export class Step3 extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const {
      values: {
        USR_FIRSTNAME,
        USR_LASTNAME,
        USR_EMAIL,
        USR_PROFILE,
        USR_BIRTHDAY,
        USR_ADDRESS,
        USR_ZIP_CODE,
        USR_POST,
        USR_ROLE,
        USR_PHONE,
      },
    } = this.props;
    return (
      <div class="pb-5">
        <h4 class="mb-10 font-weight-bold text-dark">
          Résumé des Informations saisies
        </h4>
        <h6 class="font-weight-bolder mb-3">Informations générales:</h6>
        <div class="text-dark-50 line-height-lg">
          <div>Prénom : {USR_FIRSTNAME} </div>
          <div>Nom : {USR_LASTNAME} </div>
          <div>Email : {USR_EMAIL} </div>
          <div>Date de naissance : {USR_BIRTHDAY} </div>
          <div>Numéro Code postal : {USR_ZIP_CODE} </div>
          <div>Adresse : {USR_ADDRESS} </div>
          <div>Poste : {USR_POST} </div>
          <div>Téléphone : {USR_PHONE} </div>
        </div>
        <div class="separator separator-dashed my-5"></div>

        <h6 class="font-weight-bolder mb-3">Autorisation:</h6>
        <div class="text-dark-50 line-height-lg">
          <div>Droit d'accès : {USR_ROLE} </div>
        </div>

        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.back}
            >
              Précédent
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Enregistez
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step3;
