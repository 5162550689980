import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Step6 extends Component {
  render() {
    const { values } = this.props;
    console.log(values);
    let message = "Erreur",
      icon = "flaticon-globe",
      color = "black",
      classN = "alert-light-success";
    if (values) {
      message = "Le contact a bien été enregistré.";
      icon = "flaticon-globe";
      color = "black";
      classN = "alert-light-success";
    }
    return (
      <div class="pb-5">
        <div
          className={`alert alert-custom ${classN} fade show py-4`}
          role="alert"
          style={{
            display: "block",
            textAlign: "center",
          }}
        >
          <div style={{ width: "auto" }}>
            <i
              class="flaticon-globe"
              style={{
                color,
                fontSize: "100px",
              }}
            ></i>
          </div>
          <div
            class="alert-text font-weight-bold"
            style={{
              color,
              fontSize: "20px",
            }}
          >
            {message}
          </div>
          <div className="alert-close">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="swal2-actions">
          <Link
            to={`/contacts/${values.CON_GUID}`}
            class="swal2-confirm btn font-weight-bold btn-primary"
            style={{
              marginRight: "40px",
            }}
          >
            Voir le contact
          </Link>
          <Link
            to={`/contacts`}
            class="swal2-cancel btn font-weight-bold btn-default"
            aria-label=""
          >
            Liste des contacts
          </Link>
        </div>
      </div>
    );
  }
}

export default Step6;
