import API from "../../utils/api/api";
import { getToken } from "../../session/session.controller";
/**
 *
 * @param {*} values
 * @returns
 */
const create = async (values) => {
  const token = await getToken();
  return await API.post("adm_com.php", {
    token,
    ajaxAction: "add",
    ...values,
  });
};
/**
 *
 * @returns
 */
const getAll = async (COM_OBJECT_GUID) => {
  const token = await getToken();
  return await API.post("adm_com.php", {
    token,
    ajaxAction: "list",
    COM_OBJECT_GUID,
  });
};

const deleteByGuid = async (guid) => {
  const token = await getToken();
  return await API.post("adm_com.php", {
    token,
    ajaxAction: "delete",
    COM_GUID: guid,
  });
};
export default {};
export { create, getAll, deleteByGuid };
