import React from "react";
import DetailsInfo from "./DetailsInfo";
import Password from "./Password";

const Content = () => {
  return (
    <div class="card card-custom ">
      <div class="card-body">
        <div class="tab-content">
          <DetailsInfo />
          <Password />
        </div>
      </div>
    </div>
  );
};

export default Content;
