import React, { Component } from "react";
import DetailsInfo from "./DetailsInfo";
import GererFournisseur from "./GererFournisseur";

export class Content extends Component {
  render() {
    const { guid } = this.props;
    return (
      <div class="card card-custom ">
        <div class="card-body">
          <div class="tab-content">
            <DetailsInfo guid={guid} />
            <GererFournisseur guid={guid} />
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
