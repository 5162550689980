import React, { Component } from "react";
import ListFournisseur from "../../fournisseurs/controller/ListFournisseur";
import { canSendMail } from "../controller/contact.controller";
import Select from "react-select";
import * as ReactBootStrap from "react-bootstrap";

export class SendMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FOU_GUID: "",
      loading: true,
      errorMail: false,
      CON_OBJECT_MAIL: "",
      CON_TEXT: "",
      sent: false,
    };
    this.handleSendMail = this.handleSendMail.bind(this);
    this.inputChange = this.inputChange.bind(this);
  }
  inputChange = input => async e => {
    await this.setState({ sent: false });
    if (e.value || e.value === 0) {
      this.setState({
        [input]: e.value,
      });
    } else if (e.target.value || e.target.value === 0) {
      this.setState({
        [input]: e.target.value,
      });
    } else {
    }
  };
  quitTempMail = () => {
    this.props.handleSendMail();
    this.setState({ sent: false });
  };
  async handleSendMail() {
    await this.setState({ loading: false });

    const {
      contact: { CON_GUID = null },
    } = this.props;
    const { FOU_GUID = null, CON_OBJECT_MAIL, CON_TEXT } = this.state;
    if (FOU_GUID && CON_GUID) {
      const data = { CON_GUID, FOU_GUID, CON_OBJECT_MAIL, CON_TEXT };
      const res = await canSendMail(data);
      if (res) {
        await this.setState({ sent: true });
        await this.setState({ errorMail: false });
      } else {
        await this.setState({ errorMail: true });
      }
    }
    await this.setState({ loading: true });
  }
  render() {
    const { tempMail, contact } = this.props;
    const { loading, errorMail, sent, CON_OBJECT_MAIL, CON_TEXT } = this.state;
    const { fournisseurs } = this.props;
    return (
      <div
        class="modal modal-sticky modal-sticky-bottom-right"
        style={{
          width: "80%",
          height: "80%",
          border: "1px solid black",
          display: tempMail,
          position: "fixed",
          zIndex: "1",
          bottom: 0,
          maxWidth: "900px",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div
            class="card card-custom"
            style={{ height: "-webkit-fill-available" }}
          >
            {sent ? (
              <>
                <div class="card-header align-items-center px-4 py-3">
                  <div class="text-right flex-grow-1">
                    <button type="button" onClick={this.quitTempMail}>
                      <i class="ki ki-close icon-1x"></i>
                    </button>
                  </div>{" "}
                </div>
                Mail envoyé avec succès{" "}
              </>
            ) : (
              <>
                {" "}
                <div class="card-header align-items-center px-4 py-3">
                  <div class="text-center flex-grow-1">
                    <div class="text-dark-75 font-weight-bold font-size-h2">
                      {`${contact.CON_FIRSTNAME} ${contact.CON_LASTNAME}`}
                    </div>
                  </div>
                  <div class="text-right flex-grow-1">
                    <button type="button" onClick={this.quitTempMail}>
                      <i class="ki ki-close icon-1x"></i>
                    </button>
                  </div>
                </div>
                <div class="card-header align-items-center px-4 py-3">
                  <label class="col-form-label" style={{ marginRight: "30px" }}>
                    Sélectionnez le cabinet
                  </label>
                  <div class="text-center flex-grow-1">
                    <div>
                      <Select
                        name="FOU_GUID"
                        options={fournisseurs.map(f => {
                          return {
                            label: f.FOU_NAME,
                            value: f.FOU_GUID,
                          };
                        })}
                        defaultValue={{
                          value: "",
                          label: "Sélectionnez un cabinet",
                        }}
                        onChange={this.inputChange("FOU_GUID")}
                      />
                    </div>
                  </div>
                </div>
                <div class="card-header align-items-center px-4 py-3">
                  <label class="col-form-label" style={{ marginRight: "90px" }}>
                    Objet du mail
                  </label>
                  <div class="text-center flex-grow-1">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="CON_OBJECT_MAIL"
                      onChange={this.inputChange("CON_OBJECT_MAIL")}
                      placeholder="Votre objet"
                      defaultValue={CON_OBJECT_MAIL}
                    />
                  </div>
                </div>
                <div class="card-header align-items-center px-4 py-3">
                  <label class="col-form-label" style={{ marginRight: "90px" }}>
                    Ajoutez texte
                  </label>
                  <div class="text-center flex-grow-1">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="CON_TEXT"
                      onChange={this.inputChange("CON_TEXT")}
                      placeholder="Votre texte"
                      defaultValue={CON_TEXT}
                    />
                  </div>
                </div>
                <div class="card-body">
                  <h3 class="card-label font-weight-bolder text-dark">
                    Fiche de RDV:
                  </h3>
                  <div class="py-9">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">Prénom:</span>
                      <a href="#" class="text-muted text-hover-primary">
                        {contact.CON_FIRSTNAME}
                      </a>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">Nom:</span>
                      <span class="text-muted">{contact.CON_LASTNAME}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="font-weight-bold mr-2">Téléphone:</span>
                      <span class="text-muted">{contact.CON_PHONE}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">Adresse email:</span>
                      <span class="text-muted text-hover-primary">
                        {contact.CON_EMAIL}
                      </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">Profession:</span>
                      <span class="text-muted">{contact.CON_JOB}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="font-weight-bold mr-2">
                        Date de naissance:
                      </span>
                      <span class="text-muted">{contact.CON_AGE}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">Email:</span>
                      <span class="text-muted text-hover-primary">
                        matt@fifestudios.com
                      </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">
                        Nombre d'enfant à charge:
                      </span>
                      <span class="text-muted">{contact.CON_CHILD_COUNT}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="font-weight-bold mr-2">
                        {" "}
                        Nombre de personne dans le foyer:
                      </span>
                      <span class="text-muted">
                        {contact.CON_COUNT_PEOPLE_IN_HOUSEHOLD}
                      </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">
                        Statut matrimonial:
                      </span>
                      <span class="text-muted text-hover-primary">
                        {contact.CON_MARITAL_STATUS}
                      </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <span class="font-weight-bold mr-2">Projet:</span>
                      <span class="text-muted">{contact.CON_PROJECT}</span>
                    </div>

                    <div class="d-flex align-items-center justify-content-between">
                      <span class="font-weight-bold mr-2">...</span>
                    </div>
                  </div>
                </div>
                <div
                  class="card-footer align-items-center"
                  style={{
                    position: "fixed",
                    bottom: 0,
                    width: "60%",
                    backgroundColor: "white",
                    paddingTop: 0,
                    maxWidth: "890px",
                  }}
                >
                  <div class="d-flex align-items-center justify-content-between mt-5">
                    <button
                      onClick={this.handleSendMail.bind(this)}
                      type="button"
                      class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                    >
                      Envoyer
                    </button>
                  </div>
                </div>{" "}
              </>
            )}
          </div>
        ) : (
          <ReactBootStrap.Spinner animation="border" />
        )}
      </div>
    );
  }
}
export default SendMail;
