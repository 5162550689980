import React, { Component } from "react";

export class Step2 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  previous = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values = {}, inputChange } = this.props;
    const errors = values.errors || {};
    return (
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h4 class="mb-10 font-weight-bold text-dark">Revenues & Charges</h4>
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>Revenu fiscal de référence</label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_REVENU_FISCAL_REFERENCE"
                onChange={inputChange("CON_REVENU_FISCAL_REFERENCE")}
                placeholder="... €"
                defaultValue={values.CON_REVENU_FISCAL_REFERENCE}
              />
              <span className="review-form">
                {errors && errors.CON_REVENU_FISCAL_REFERENCE}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Revenu foncier</label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_REVENU"
                onChange={inputChange("CON_REVENU")}
                placeholder="... €"
                defaultValue={values.CON_REVENU}
              />
              <span className="review-form">{errors && errors.CON_REVENU}</span>
            </div>
          </div>

          <div class="col-xl-4">
            <div class="form-group">
              <label>Crédit automobile </label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_CREDIT_CAR"
                onChange={inputChange("CON_CREDIT_CAR")}
                placeholder="... €"
                defaultValue={values.CON_CREDIT_CAR}
              />
              <span className="review-form">
                {errors && errors.CON_CREDIT_CAR}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>Crédit conso </label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_CREDIT_CONSO"
                onChange={inputChange("CON_CREDIT_CONSO")}
                placeholder="... €"
                defaultValue={values.CON_CREDIT_CONSO}
              />
              <span className="review-form">
                {errors && errors.CON_CREDIT_CONSO}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Crédit immobilier </label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_CREDIT_IMMO"
                onChange={inputChange("CON_CREDIT_IMMO")}
                placeholder="... €"
                defaultValue={values.CON_CREDIT_IMMO}
              />
              <span className="review-form">
                {errors && errors.CON_CREDIT_IMMO}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Autres crédits</label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_CREDIT_OTHER"
                onChange={inputChange("CON_CREDIT_OTHER")}
                placeholder="... €"
                defaultValue={values.CON_CREDIT_OTHER}
              />
              <span className="review-form">
                {errors && errors.CON_CREDIT_OTHER}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label> Montant loyer </label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_MONTANT_LOYER"
                onChange={inputChange("CON_MONTANT_LOYER")}
                placeholder="... €"
                defaultValue={values.CON_MONTANT_LOYER}
              />
              <span className="review-form">
                {errors && errors.CON_MONTANT_LOYER}
              </span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label> Montant de l'impôt </label>
              <input
                type="number"
                class="form-control form-control-solid form-control-lg"
                name="CON_IMPOT"
                onChange={inputChange("CON_IMPOT")}
                placeholder="... €"
                defaultValue={values.CON_IMPOT}
              />
              <span className="review-form">{errors && errors.CON_IMPOT}</span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Taux d'endettement </label>
              <span
                class="form-control form-control-solid form-control-lg"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {`${values.CON_TAUX_ENDETTEMENT || 0} %`}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.previous}
            >
              Précédent
            </button>
          </div>
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
