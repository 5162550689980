import React, { Component } from "react";

export class Step2 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const {
      values: {
        FOU_NAME,
        FOU_EMAIL,
        FOU_ZONE,
        FOU_ZIP_CODE,
        FOU_ADDRESS,
        FOU_COUNTRY,
        FOU_PHONE,
      },
    } = this.props;
    return (
      <div>
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Résumé des informations saisies
          </h4>
          <h6 class="font-weight-bolder mb-3">Nom du fourniseur:</h6>
          <div class="text-dark-50 line-height-lg">
            <div>{FOU_NAME}</div>
          </div>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="font-weight-bolder mb-3">Adresse Email:</h6>
          <div class="text-dark-50 line-height-lg">
            <div>{FOU_EMAIL}</div>
          </div>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="font-weight-bolder mb-3">Téléphone:</h6>
          <div class="text-dark-50 line-height-lg">
            <div>{FOU_PHONE}</div>
          </div>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="font-weight-bolder mb-3">Secteur d'activités:</h6>
          <div class="text-dark-50 line-height-lg">
            <div>{FOU_ZONE}</div>
          </div>
          <div class="separator separator-dashed my-5"></div>

          <h6 class="font-weight-bolder mb-3">Code postal:</h6>
          <div class="text-dark-50 line-height-lg">
            <div>{FOU_ZIP_CODE}</div>
          </div>
          <div class="separator separator-dashed my-5"></div>

          <h6 class="font-weight-bolder mb-3">Adresse:</h6>
          <div class="text-dark-50 line-height-lg">
            <div>{FOU_ADDRESS}</div>
          </div>
          <div class="separator separator-dashed my-5"></div>

          <h6 class="font-weight-bolder mb-3">Pays:</h6>
          <div class="text-dark-50 line-height-lg">
            <div>{FOU_COUNTRY}</div>
          </div>
          <div class="separator separator-dashed my-5"></div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.back}
            >
              Précédent
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Enregistez
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
