import React, { Component } from "react";
import ButtonReturn from "../utils/button/ButtonReturn";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import InfoNav from "./InfoComposants/InfoNav";
import Content from "./composants/info/Content";
import { findOne, update } from "./controller/employee.controller";
import { initState } from "./controller/employee.model";
import history from "../utils/history";
import * as ReactBootStrap from "react-bootstrap";

export class InfoEmploye extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employe: initState(),
      loading: false,
      updateError: false,
      success: false,
      error: false,
    };
    this.canUpdate = this.canUpdate.bind(this);
  }
  async canUpdate(data, changeExist) {
    await this.setState({ loading: false });
    const { exist = false, errors = false, message = {} } = changeExist;
    const { USR_GUID } = this.state.employe;
    if (exist && !errors) {
      const res = await update(data, USR_GUID);
      if (res) {
        await this.setState({ employe: res, success: true });
      } else {
        await this.setState({ updateError: true });
      }
    }
    if (errors) {
      await this.setState({ error: { CON_EMAIL: message.CON_EMAIL } });
    }
    await this.setState({ loading: true });
  }
  async componentWillMount() {
    const { guid } = this.props.match.params;
    const employe = await findOne(guid);
    console.log(employe);
    if (employe) {
      await this.setState({ employe: employe });
    }
    // await history.push(`/employes/${guid}`);
    await this.setState({ loading: true });
  }
  render() {
    const { guid } = this.props.match.params;
    const { loading, ...data } = this.state;
    return (
      <>
        {loading ? (
          <div
            class="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <ButtonPageTitle title="EMPLOYES" />
            <div class="d-flex flex-column-fluid">
              <div class="container">
                <ButtonReturn fullname="RETOUR" ressource="employe" />
                <InfoNav guid={guid} active={"info"} />
                <Content data={data} canUpdate={this.canUpdate} />
              </div>
            </div>
          </div>
        ) : (
          <ReactBootStrap.Spinner animation="border" />
        )}
      </>
    );
  }
}

export default InfoEmploye;
