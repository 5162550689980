import React, { Component } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { initState } from "../controller/contact.model";
import {
  ifErrorExistInForm,
  addContact,
} from "../controller/contact.controller";
import { tauxEndettement } from "../controller/contact.mapper";

export class Steps extends Component {
  state = {
    step: 1,
    ...initState(),
    results: "",
    errors: false,
    CON_TAUX_ENDETTEMENT: 0,
  };

  nextStep = async () => {
    const { step, results, errors, CON_TAUX_ENDETTEMENT, ...values } =
      this.state;
    if (step === 5) {
      const result = await addContact(values);
      await this.setState({ results: result });
      await this.setState({ step: step + 1 });
    } else {
      const errorExist = await ifErrorExistInForm(this.state);
      if (!errorExist) {
        await this.setState({ step: step + 1 });
        await this.setState({ errors: false });
        this.props.steps(step + 1);
      } else {
        await this.setState({ errors: errorExist });
      }
    }
  };

  prevStep = async () => {
    const { step } = this.state;
    await this.setState({ step: step - 1 });
    await this.props.steps(step - 1);
  };

  inputChange = input => async e => {
    if (e.value || e.value === 0) {
      await this.setState({
        [input]: e.value,
      });
    } else if (e.target.value || e.target.value === 0) {
      await this.setState({
        [input]: e.target.value,
      });
    } else {
    }
    const { step } = this.state;

    if (step === 2) {
      await this.setState({
        CON_TAUX_ENDETTEMENT: await tauxEndettement(await this.state),
      });
    }
  };

  render() {
    const { step, results } = this.state;
    const { state } = this;

    switch (step) {
      case 1:
        return (
          <Step1
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 3:
        return (
          <Step3
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 4:
        return (
          <Step4
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 5:
        return (
          <Step5
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 6:
        return <Step6 values={results} />;
    }
  }
}
export default Steps;
