"use strict";
import React from "react";
import { Link } from "react-router-dom";
import { ROLE } from "../../../employes/controller/employee.const";

const Menu = ({ user }) => {
  return (
    <div
      class="aside-menu-wrapper flex-column-fluid"
      id="kt_aside_menu_wrapper"
    >
      <div
        id="kt_aside_menu"
        class="aside-menu my-4"
        data-menu-vertical="1"
        data-menu-scroll="1"
        data-menu-dropdown-timeout="500"
      >
        <ul class="menu-nav">
          <li class={`menu-item `} aria-haspopup="true">
            <Link to="/" class="menu-link menu-toggle">
              <i class="menu-icon flaticon2-architecture-and-city"></i>
              <span class="menu-text">DASHBOARD</span>
            </Link>
          </li>
          <li
            class={`menu-item `}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <Link to="/contacts" class="menu-link menu-toggle">
              <i class="menu-icon flaticon2-avatar"></i>
              <span class="menu-text">CONTACTS</span>
            </Link>
          </li>
          {user.USR_ROLE === ROLE.ADMIN && (
            <li
              class={`menu-item `}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <Link to="/fournisseurs" class="menu-link menu-toggle">
                <i class="menu-icon flaticon2-shelter"></i>
                <span class="menu-text">CABINETS </span>
              </Link>
            </li>
          )}

          <li
            class={`menu-item `}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <Link to="/employes" class="menu-link menu-toggle">
              <i class="menu-icon flaticon2-user-1"></i>
              <span class="menu-text">EMPLOYES</span>
            </Link>
          </li>
          {user.USR_ROLE === ROLE.ADMIN && (
            <li class={`menu-item `} aria-haspopup="true">
              <Link to="/lead" class="menu-link">
                <i class="menu-icon flaticon2-rocket"></i>
                <span class="menu-text">LEAD</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
