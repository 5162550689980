function validateStep1(values) {
  let errors = {};
  const {
    USR_FIRSTNAME,
    USR_LASTNAME,
    USR_EMAIL,
    USR_PROFILE,
    USR_BIRTHDAY,
    USR_ADDRESS,
    USR_ZIP_CODE,
    USR_POST,
    USR_ROLE,
    USR_PHONE,
  } = values;
  if (!USR_EMAIL) {
    errors.USR_EMAIL = "Email obligatoire";
    return errors;
  } else if (!/\S+@\S+\.\S+/.test(USR_EMAIL)) {
    errors.USR_EMAIL = "L'adresse Email est invalide";
    return errors;
  }
  return false;
}

const validatorUpdate = (
  {
    CON_FIRSTNAME,
    CON_LASTNAME,
    CON_PHONE,
    CON_EMAIL,
    CON_AGE,
    CON_JOB,
    CON_STATUS,
    CON_MARITAL_STATUS,
    CON_PROJECT,
    CON_ADDRESS,
    CON_CITY,
    CON_ZIP_CODE,
    CON_CHILD_COUNT,
    CON_COUNT_PEOPLE_IN_HOUSEHOLD,
    CON_RDV_TAKEN_ON,
    CON_DATE_RDV,
    CON_PLACE_RDV,
    CON_ISSU_RDV,
    CON_REVENU,
    CON_REVENU_FISCAL_REFERENCE,
    CON_REVENU_FONCIER,
    CON_MONTANT_LOYER,
    CON_CREDIT_IMMO,
    CON_CREDIT_CAR,
    CON_CREDIT_CONSO,
    CON_CREDIT_OTHER,
    CON_COMMENT,
    CON_NOTE,
  },
  contact
) => {
  let change = {
    exist: false,
    errors: false,
    message: {},
  };

  if (CON_EMAIL && contact.CON_EMAIL !== CON_EMAIL.trim()) {
    if (!/\S+@\S+\.\S+/.test(CON_EMAIL)) {
      change.errors = true;
      change.message = { CON_EMAIL: "Adresse Email invalide" };
    } else {
      change.exist = true;
    }
  }
  if (CON_FIRSTNAME && CON_FIRSTNAME.trim() !== contact.CON_FIRSTNAME) {
    change.exist = true;
  }
  if (CON_LASTNAME && CON_LASTNAME.trim() !== contact.CON_LASTNAME) {
    change.exist = true;
  }
  if (CON_PHONE && CON_PHONE.trim() !== contact.CON_PHONE) {
    change.exist = true;
  }
  if (CON_AGE && CON_AGE.trim() !== contact.CON_AGE) {
    change.exist = true;
  }
  if (CON_JOB && CON_JOB.trim() !== contact.CON_JOB) {
    change.exist = true;
  }
  if (CON_STATUS && CON_STATUS.trim() !== contact.CON_STATUS) {
    change.exist = true;
  }
  if (CON_PLACE_RDV && CON_PLACE_RDV.trim() !== contact.CON_PLACE_RDV) {
    change.exist = true;
  }
  if (CON_DATE_RDV && CON_DATE_RDV.trim() !== contact.CON_DATE_RDV) {
    change.exist = true;
  }
  if (
    CON_RDV_TAKEN_ON &&
    CON_RDV_TAKEN_ON.trim() !== contact.CON_RDV_TAKEN_ON
  ) {
    change.exist = true;
  }

  if (
    CON_COUNT_PEOPLE_IN_HOUSEHOLD &&
    CON_COUNT_PEOPLE_IN_HOUSEHOLD.trim() !==
      contact.CON_COUNT_PEOPLE_IN_HOUSEHOLD
  ) {
    change.exist = true;
  }
  if (CON_CHILD_COUNT && CON_CHILD_COUNT.trim() !== contact.CON_CHILD_COUNT) {
    change.exist = true;
  }
  if (CON_ZIP_CODE && CON_ZIP_CODE.trim() !== contact.CON_ZIP_CODE) {
    change.exist = true;
  }
  if (CON_CITY && CON_CITY.trim() !== contact.CON_CITY) {
    change.exist = true;
  }
  if (CON_ADDRESS && CON_ADDRESS.trim() !== contact.CON_ADDRESS) {
    change.exist = true;
  }
  if (CON_PROJECT && CON_PROJECT.trim() !== contact.CON_PROJECT) {
    change.exist = true;
  }
  if (
    CON_MARITAL_STATUS &&
    CON_MARITAL_STATUS.trim() !== contact.CON_MARITAL_STATUS
  ) {
    change.exist = true;
  }
  if (CON_NOTE && CON_NOTE.trim() !== contact.CON_NOTE) {
    change.exist = true;
  }
  if (CON_COMMENT && CON_COMMENT.trim() !== contact.CON_COMMENT) {
    change.exist = true;
  }
  if (
    CON_CREDIT_OTHER &&
    CON_CREDIT_OTHER.trim() !== contact.CON_CREDIT_OTHER
  ) {
    change.exist = true;
  }
  if (
    CON_CREDIT_CONSO &&
    CON_CREDIT_CONSO.trim() !== contact.CON_CREDIT_CONSO
  ) {
    change.exist = true;
  }
  if (CON_CREDIT_CAR && CON_CREDIT_CAR.trim() !== contact.CON_CREDIT_CAR) {
    change.exist = true;
  }
  if (CON_CREDIT_IMMO && CON_CREDIT_IMMO.trim() !== contact.CON_CREDIT_IMMO) {
    change.exist = true;
  }

  if (
    CON_MONTANT_LOYER &&
    CON_MONTANT_LOYER.trim() !== contact.CON_MONTANT_LOYER
  ) {
    change.exist = true;
  }
  if (
    CON_REVENU_FONCIER &&
    CON_REVENU_FONCIER.trim() !== contact.CON_REVENU_FONCIER
  ) {
    change.exist = true;
  }
  if (
    CON_REVENU_FISCAL_REFERENCE &&
    CON_REVENU_FISCAL_REFERENCE.trim() !== contact.CON_REVENU_FISCAL_REFERENCE
  ) {
    change.exist = true;
  }
  if (CON_REVENU && CON_REVENU.trim() !== contact.CON_REVENU) {
    change.exist = true;
  }
  if (CON_ISSU_RDV && CON_ISSU_RDV.trim() !== contact.CON_ISSU_RDV) {
    change.exist = true;
  }

  return change;
};

export default {};
export { validateStep1, validatorUpdate };
