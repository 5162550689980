import React, { Component } from "react";
import SendMail from "../SendMail";
import { recapContactItem } from "../../controller/contact.mapper";
import { ROLE } from "../../../employes/controller/employee.const";
import ListFournisseur from "../../../fournisseurs/controller/ListFournisseur";
import Select from "react-select";

export class Recap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CON_FOURNISSEUR_GUID: "",
      tempMail: "none",
      editCab: false,
      fournisseurs: [],
    };
    this.handleSendMail = this.handleSendMail.bind(this);
    this.editCabinet = this.editCabinet.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleSendMail() {
    if (this.state.tempMail === "none") {
      this.setState({ tempMail: "block" });
    }
    if (this.state.tempMail === "block") {
      this.setState({ tempMail: "none" });
    }
  }
  inputChange = input => async e => {
    this.setState({
      [input]: e.value,
    });
  };
  editCabinet() {
    const { editCab } = this.state;
    this.setState({ editCab: !editCab });
  }
  handleClick = async () => {
    const { canUpdate } = this.props;
    const { CON_FOURNISSEUR_GUID } = this.state;
    await canUpdate({ CON_FOURNISSEUR_GUID }, { exist: true, errors: false });
  };

  render() {
    const con = this.props.data.contact;
    const contact = recapContactItem(con);
    const { editCab } = this.state;
    const { fournisseurs } = this.props;
    return (
      <>
        <div>
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <div class="d-flex mb-9">
                <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                  <div class="symbol symbol-50 symbol-lg-120 symbol-primary ">
                    <span class="font-size-h3 symbol-label font-weight-boldest">
                      {contact.prefixe}
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="d-flex justify-content-between flex-wrap mt-1">
                    <div class="d-flex mr-3">
                      <a
                        href="#"
                        class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                      >
                        {contact.conName}
                      </a>
                    </div>
                    {contact.CON_USR_CONNECT_AUTH === ROLE.ADMIN && (
                      <div class="my-lg-0 my-3">
                        <button
                          to="#"
                          class="btn btn-sm btn-info font-weight-bolder text-uppercase"
                          data-target="#kt_chat_modal"
                          onClick={this.handleSendMail}
                        >
                          Envoyer email
                        </button>
                      </div>
                    )}
                  </div>
                  {contact.CON_USR_CONNECT_AUTH === ROLE.ADMIN && editCab ? (
                    <div style={{ textAlign: "end", marginTop: "15px" }}>
                      <div
                        style={{
                          width: "200px",
                          display: "inline-block",
                          marginRight: "10px",
                          border: "2px #da0806 solid",
                          borderRadius: "5px",
                        }}
                      >
                        <Select
                          name="CON_FOURNISSEUR_GUID"
                          options={fournisseurs.map(f => {
                            return {
                              label: f.FOU_NAME,
                              value: f.FOU_GUID,
                            };
                          })}
                          defaultValue={{
                            value: "",
                            label: "Sélectionnez un cabinet",
                          }}
                          onChange={this.inputChange("CON_FOURNISSEUR_GUID")}
                        />
                      </div>
                      <button
                        style={{ marginRight: "5px" }}
                        class="btn btn-sm btn-info font-weight-bolder text-uppercase"
                        data-target="#kt_chat_modal"
                        onClick={this.handleClick}
                      >
                        Enregister
                      </button>
                      <button
                        class="btn btn-sm btn-info font-weight-bolder text-uppercase"
                        data-target="#kt_chat_modal"
                        onClick={this.editCabinet}
                      >
                        Annuler
                      </button>
                    </div>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        right: "30px",
                        marginTop: "15px",
                      }}
                    >
                      <button
                        to="#"
                        class="btn btn-sm btn-info font-weight-bolder text-uppercase"
                        data-target="#kt_chat_modal"
                        onClick={this.editCabinet}
                      >
                        Modifier cabinet
                      </button>
                    </div>
                  )}

                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                      <div class="info-contact d-flex flex-wrap mb-4">
                        <i
                          class="flaticon2-new-email mr-2 font-size-lg"
                          style={{ color: "black" }}
                        ></i>
                        {`${contact.email}`}
                      </div>
                      <div class="d-flex flex-wrap mb-4">
                        <i
                          class="flaticon2-calendar-3 mr-2 font-size-lg"
                          style={{ color: "black" }}
                        ></i>
                        {`${contact.job}`}
                      </div>
                      <div class="d-flex flex-wrap mb-4">
                        <i
                          class="flaticon2-placeholder mr-2 font-size-lg"
                          style={{ color: "black" }}
                        ></i>
                        {`${contact.adresse}`}
                      </div>
                      <div class="d-flex flex-wrap mb-4">
                        <i
                          class="flaticon2-placeholder mr-2 font-size-lg"
                          style={{ color: "black" }}
                        ></i>
                        {contact.codePostal}
                      </div>
                    </div>
                    <div class="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8">
                      <div class="info-centrer d-flex flex-column text-dark-75">
                        <span class="titre-info-centrer">Date d'ajout</span>
                        <span class="label label-lg font-weight-bolder font-size-sm label-light-success label-inline">
                          {contact.dateAjout}
                        </span>
                      </div>
                      <div class="info-centrer d-flex flex-column text-dark-75">
                        <span class="titre-info-centrer ">Date de RDV</span>
                        <span class="label label-lg label-light-danger label-inline font-weight-bolder font-size-sm">
                          {contact.dateRdv}
                        </span>
                      </div>
                      <div class="info-centrer d-flex flex-column text-dark-75">
                        <span class="titre-info-centrer ">Cabinet</span>
                        <span class="label label-lg font-weight-bolder font-size-sm label-light-dark label-inline">
                          {contact.fournisseur}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator separator-solid"></div>
              <div class="d-flex align-items-center flex-wrap mt-8">
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i
                      class="flaticon-piggy-bank display-4 font-weight-bold"
                      style={{ color: "#da0806" }}
                    ></i>
                  </span>
                  <div class="d-flex flex-column" style={{ color: "#da0806" }}>
                    <span class="font-weight-bolder font-size-sm">
                      Taux d'endettement
                    </span>
                    <span class="font-weight-bolder font-size-h5">
                      {contact.tauxEndettement}
                      <span class=" font-weight-bold">%</span>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i class="flaticon-piggy-bank display-4 font-weight-bold"></i>
                  </span>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder font-size-sm">
                      Montant de l'impôt
                    </span>
                    <span class="font-weight-bolder font-size-h5">
                      {contact.impot} <span class="font-weight-bold">€</span>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i
                      class="flaticon-piggy-bank display-4 font-weight-bold"
                      style={{ color: "rgb(0 146 52)" }}
                    ></i>
                  </span>
                  <div
                    class="d-flex flex-column"
                    style={{ color: "rgb(0 146 52)" }}
                  >
                    <span class="font-weight-bolder font-size-sm">Revenus</span>
                    <span class="font-weight-bolder font-size-h5">
                      {contact.revenus} <span class="font-weight-bold">€</span>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i class="flaticon-piggy-bank display-4 font-weight-bold"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                    <span class="font-weight-bolder font-size-sm">
                      Dépenses
                    </span>
                    <span class="font-weight-bolder font-size-h5">
                      {contact.depenses}{" "}
                      <span class="text-dark-50 font-weight-bold">€</span>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                    <span class="font-weight-bolder font-size-sm">Crédits</span>
                    <span class="font-weight-bolder font-size-h5">
                      {contact.credits}{" "}
                      <span class="text-dark-50 font-weight-bold">€</span>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                  <span class="mr-4">
                    <i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                    <span class="font-weight-bolder font-size-sm">Projet</span>
                    <span class="font-weight-bolder font-size-h5">
                      {contact.projet}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SendMail
            tempMail={this.state.tempMail}
            handleSendMail={this.handleSendMail}
            contact={con}
            fournisseurs={this.props.fournisseurs}
          />
        </div>
      </>
    );
  }
}

export default Recap;
