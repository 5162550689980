import React, { Component } from "react";
import { OPTION } from "../controller/employee.const";
import Select from "react-select";

export class Step1 extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    const { values = {}, inputChange } = this.props;
    const errors = values.errors || {};
    return (
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h3 class="mb-10 font-weight-bold text-dark">Informations Générales</h3>
        <div class="form-group">
          <label>Prénom</label>
          <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            name="USR_FIRSTNAME"
            onChange={inputChange("USR_FIRSTNAME")}
            placeholder="Valerie"
            defaultValue={values.USR_FIRSTNAME}
          />
          <span class="form-text text-muted">
            {errors && errors.USR_FIRSTNAME}
          </span>
        </div>
        <div class="form-group">
          <label>Nom</label>
          <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            name="USR_LASTNAME"
            onChange={inputChange("USR_LASTNAME")}
            placeholder="Maisonnave"
            defaultValue={values.USR_LASTNAME}
          />
          <span class="form-text text-muted">
            {errors && errors.USR_LASTNAME}
          </span>
        </div>
        <div class="form-group">
          <label>Email</label>
          <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            name="USR_EMAIL"
            onChange={inputChange("USR_EMAIL")}
            placeholder="valerie@gmail.com"
            defaultValue={values.USR_EMAIL}
          />
          <span class="form-text text-muted">{errors && errors.USR_EMAIL}</span>
        </div>
        <div class="form-group">
          <label>Date de naissance</label>
          <input
            type="date"
            class="form-control form-control-solid form-control-lg"
            name="USR_BIRTHDAY"
            onChange={inputChange("USR_BIRTHDAY")}
            defaultValue={values.USR_BIRTHDAY}
          />
          <span class="form-text text-muted">
            {errors && errors.USR_BIRTHDAY}
          </span>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Code Postal</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="USR_ZIP_CODE"
                onChange={inputChange("USR_ZIP_CODE")}
                defaultValue={values.USR_ZIP_CODE}
              />
              <span class="form-text text-muted">
                {errors && errors.USR_ZIP_CODE}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Adresse</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="USR_ADDRESS"
                onChange={inputChange("USR_ADDRESS")}
                defaultValue={values.USR_ADDRESS}
              />
              <span class="form-text text-muted">
                {errors && errors.USR_ADDRESS}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Poste</label>
              <Select
                name="USR_POST"
                options={OPTION.OPTION_POST}
                value={{
                  value: values.USR_POST,
                  label: values.USR_POST,
                }}
                onChange={inputChange("USR_POST")}
                defaultValue={OPTION.OPTION_POST[0]}
              />
              <span class="form-text text-muted">
                {errors && errors.USR_POST}
              </span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Téléphone</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="USR_PHONE"
                onChange={inputChange("USR_PHONE")}
                placeholder="0607849394"
                defaultValue={values.USR_PHONE}
              />
              <span class="form-text text-muted">
                {errors && errors.USR_PHONE}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step1;
