import React, { Component } from "react";
import { OPTION } from "../controller/employee.const";
import Select from "react-select";

export class Step2 extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, inputChange } = this.props;
    return (
      <div class="pb-5">
        <h4 class="mb-10 font-weight-bold text-dark">
          Affectez un droit à l'utilisateur
        </h4>

        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>Droit d'accès</label>
              <Select
                name="USR_ROLE"
                options={OPTION.OPTION_ACCESS}
                value={{
                  value: values.USR_ROLE,
                  label: values.USR_ROLE,
                }}
                onChange={inputChange("USR_ROLE")}
                defaultValue={OPTION.OPTION_ACCESS[0]}
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.back}
            >
              Précédent
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
