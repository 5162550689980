import React from "react";

const DetailsInfo = () => {
  return (
    <div class="tab-pane show active pt_user_edit_tab_1" role="tabpanel">
      <div class="row ">
        <div class="col-xl-7">
          <form>
            <div class="row">
              <label class="col-3"></label>
              <div class="col-9">
                <h6 class="text-dark font-weight-bold mb-10">
                  Informations générales
                </h6>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Avatar
              </label>
              <div class="col-9">
                <div
                  class="image-input image-input-empty image-input-outline"
                  id="kt_user_edit_avatar"
                  style={{
                    backgroundImage: "url(assets/media/users/blank.png)",
                  }}
                >
                  <div class="image-input-wrapper"></div>

                  <label
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i class="fa fa-pen icon-sm text-muted"></i>
                    <input type="hidden" name="profile_avatar_remove"></input>
                    <input type="hidden" name="profile_avatar_remove"></input>
                  </label>

                  <span
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="cancel"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Cancel avatar"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="remove"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Remove avatar"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                First Name
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  value="Anna"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Last Name
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  value="Krox"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Company Name
              </label>
              <div class="col-9">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  value="Loop Inc."
                />
                <span class="form-text text-muted">
                  If you want your invoices addressed to a company. Leave blank
                  to use your full name.
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Contact Phone
              </label>
              <div class="col-9">
                <div class="input-group input-group-lg input-group-solid">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-phone"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    value="+45678967456"
                    placeholder="Phone"
                  />
                </div>
                <span class="form-text text-muted">
                  We'll never share your email with anyone else.
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Email Address
              </label>
              <div class="col-9">
                <div class="input-group input-group-lg input-group-solid">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-at"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    value="anna.krox@loop.com"
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-3 text-lg-right text-left">
                Company Site
              </label>
              <div class="col-9">
                <div class="input-group input-group-lg input-group-solid">
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Username"
                    value="loop"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">.com</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group text-right">
              <div class="col-9">
                <button
                  href="#"
                  class="btn btn-light-primary font-weight-bold "
                >
                  Enregister
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DetailsInfo;
