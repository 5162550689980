import React, { Component } from "react";
import Step1 from "./Step1";

export class Content extends Component {
  render() {
    return <Step1 />;
  }
}

export default Content;
