import React, { Component } from "react";
import {
  findAll,
  addComment,
  deleteCom,
} from "../../../comment/controller/comment.controller";
import * as ReactBootStrap from "react-bootstrap";
import { Link } from "react-router-dom";

export class Commentaires extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      COM_VARIABLE: "",
      loading: false,
      error: false,
    };
    this.inputChange = this.inputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.deleteClick = this.deleteClick.bind(this);
  }
  inputChange = input => async e => {
    this.setState({
      [input]: e.target.value,
    });
  };
  handleClick = async () => {
    await this.setState({ loading: false });
    const { COM_VARIABLE } = this.state;
    const { guid } = this.props;
    if (COM_VARIABLE) {
      await addComment({ COM_VARIABLE, COM_OBJECT_GUID: guid });
      const comments = await findAll(guid);
      await this.setState({ comments: comments });
      await this.setState({ COM_VARIABLE: "" });
    } else {
      await this.setState({ error: true });
    }
    await this.setState({ loading: true });
  };
  deleteClick = async guid => {
    await this.setState({ loading: false });

    const d = await deleteCom(guid);
    const comments = await findAll(this.props.guid);
    await this.setState({ comments: comments });
    await this.setState({ loading: true });
  };
  async componentWillMount() {
    const { guid } = this.props;
    const comments = await findAll(guid);
    if (comments.length) {
      await this.setState({ comments: comments });
    }
    this.setState({ loading: true });
  }
  render() {
    const { loading, comments, COM_VARIABLE, error } = this.state;
    return (
      <div class="container">
        <h6 class="text-dark font-weight-bold mb-10">Commentaires</h6>
        <>
          <div class="form-group">
            <textarea
              class="form-control form-control-lg form-control-solid"
              id="exampleTextarea"
              rows="3"
              placeholder="Ajouter commentaire"
              onChange={this.inputChange("COM_VARIABLE")}
              name="COM_VARIABLE"
            ></textarea>
            <span style={{ color: "red" }}>
              {error && "Veuillez saisir un commentaire"}
            </span>
          </div>
          <div class="row">
            <div class="col">
              <button
                onClick={this.handleClick}
                class="btn btn-light-primary font-weight-bold"
                style={{ backgroundColor: "#adadad" }}
              >
                Commenter
              </button>
            </div>
          </div>
        </>
        <div class="timeline timeline-3">
          <div class="timeline-items">
            {loading ? (
              comments.map(c => {
                return (
                  <div
                    class="timeline-item"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <div class="timeline-media">
                      <span class="symbol-label font-size-h5 font-weight-bold">
                        {c.USR_NAME.charAt(0)}
                      </span>
                    </div>
                    <div class="timeline-content">
                      <div class="d-flex align-items-center justify-content-between mb-3">
                        <div class="mr-2">
                          <Link
                            to={`/employes/${c.USR_GUID}`}
                            class="text-dark-75 text-hover-primary font-weight-bold"
                          >
                            {c.USR_NAME}
                          </Link>
                          <span class="text-muted ml-2">{c.COM_CREATED}</span>
                          <button
                            type="submit"
                            class="label font-weight-bolder label-inline ml-2"
                            style={{
                              padding: "0",
                              border: "0",
                            }}
                            onClick={this.deleteClick.bind(this, c.COM_GUID)}
                          >
                            <i
                              class="flaticon-delete "
                              style={{
                                color: "red",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      <p class="p-0">{c.COM_VARIABLE}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <ReactBootStrap.Spinner animation="border" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Commentaires;
