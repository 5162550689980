"use strict";
import React from "react";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import ButtonAdd from "../utils/button/ButtonAdd";
import EmployesList from "./composants/EmployesList";

const Employes = () => {
  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <ButtonPageTitle title="EMPLOYES" />
      <div class="d-flex flex-column-fluid">
        <div class="container">
          <ButtonAdd ressource="employe" />
          <EmployesList />
        </div>
      </div>
    </div>
  );
};

export default Employes;
