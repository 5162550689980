import moment from "moment";

function initProps() {
  return {
    COM_VARIABLE: "",
    COM_USER_GUID: "",
    COM_OBJECT_GUID: "",
  };
}

function commentItem(comment) {
  if (!comment) {
    return initProps();
  }
  const { COM_CREATED, USR_FIRSTNAME, USR_LASTNAME, ...com } = comment;
  return {
    USR_NAME: `${USR_FIRSTNAME || ""} ${USR_LASTNAME || ""}`,
    COM_CREATED: moment(COM_CREATED).format("DD/MM/YYYY à HH:mm"),
    ...com,
  };
}

export default {};
export { initProps, commentItem };
