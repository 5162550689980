"use strict";
import React from "react";
import LogoMobile from "./LogoMobile";
import Tolbar from "./Tolbar";

const HeaderMobile = () => {
  return (
    <div
      id="kt_header_mobile"
      class="header-mobile align-items-center header-mobile-fixed"
    >
      <LogoMobile />
      <Tolbar />
    </div>
  );
};

export default HeaderMobile;
