import React from "react";

const Footer = () => {
  return (
    <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
      <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div class="text-dark order-2 order-md-1">
          <span class="text-muted font-weight-bold mr-2">2021©</span>
          <span class="text-dark-75 text-hover-primary">
            Copyright--K2M CRM
          </span>
        </div>
        <div class="nav nav-dark">
          <a href="/footer" target="_blank" class="nav-link pl-0 pr-5">
            Support Technique
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
