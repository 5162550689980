import { create, getAll, deleteByGuid } from "./comment.dao";
import { commentItem } from "./comment.mapper";
import moment from "moment";
/**
 *
 * @param {*} values
 * @returns
 */
async function addComment(values) {
  const { COM_VARIABLE, COM_OBJECT_GUID } = values;
  const rows = {
    COM_OBJECT_GUID,
    COM_VARIABLE,
  };
  try {
    const result = await create(rows);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
/**
 *
 * @returns
 */
async function findAll(objectGuid) {
  try {
    const result = await getAll(objectGuid);
    const { data } = result;
    let mapper = [];
    if (data.length) {
      for (const d of data) {
        mapper.push(commentItem(d));
      }
    }
    return Promise.all(mapper);
  } catch (error) {
    console.log(`findAll : ${error}`);
    return false;
  }
}

async function deleteCom(guid) {
  try {
    const result = await deleteByGuid(guid);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export default {};
export { addComment, findAll, deleteCom };
