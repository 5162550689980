import React, { Component } from "react";
import ButtonReturn from "../utils/button/ButtonReturn";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import InfoNav from "./InfoComposants/InfoNav";
import Content from "./composants/delete/Content";
import history from "../utils/history";

export class InfoEmploye extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    const { guid } = this.props.match.params;
    // history.push(`/employes/${guid}/delete`);
  }
  render() {
    const { guid } = this.props.match.params;
    return (
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <ButtonPageTitle title="EMPLOYES" />
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <ButtonReturn fullname="RETOUR" ressource="employe" />
            <InfoNav guid={guid} active={"delete"} />
            <div class="card card-custom " id="contact-info-affichage">
              <Content guid={guid} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoEmploye;
