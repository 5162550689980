const OPTION_NAME = {
  OPTION_MATRIMONIALE: "OPTION_MATRIMONIALE",
  OPTION_PROPRIETAIRE: "OPTION_PROPRIETAIRE",
  OPTION_OBJECTIF: "OPTION_OBJECTIF",
};

const OPTION = {
  [OPTION_NAME.OPTION_MATRIMONIALE]: [
    { label: "Célibataire", value: "celibataire" },
    { label: "Marié", value: "marie" },
    { label: "Concubinage", value: "concubinage" },
    { label: "Divorce", value: "divorce" },
    { label: "Veuf", value: "veuf" },
    { label: "Pacsé", value: "pacse" },
  ],
  [OPTION_NAME.OPTION_PROPRIETAIRE]: [
    { label: "Propriétaire", value: "proprietaire" },
    { label: "Locataire", value: "locataire" },
  ],
  [OPTION_NAME.OPTION_OBJECTIF]: [
    { label: "Réduction impôts", value: "reduction_impots" },
    { label: "Patrimoine", value: "patrimoine" },
    { label: "Décés", value: "deces" },
    { label: "Retraite", value: "retraite" },
  ],
};
const response = {
  UNDEFINED: "Pas défini",
  INTEROGATION: "??",
};
const ROLE = {
  GESTIONNAIRE: "GESTIONNAIRE",
  ADMIN: "ADMIN",
};
export default {};
export { OPTION_NAME, OPTION, response, ROLE };
