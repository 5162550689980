import { validateStep1 } from "./employee.validator";
import {
  create,
  getAll,
  getByGuid,
  setByGuid,
  deleteByGuid,
  getContactsByRhGuid,
} from "./employee.dao";
import { employeeItem } from "./employee.mapper";
import { contactItem } from "../../contacts/controller/contact.mapper";
import moment from "moment";
function ifErrorExistInForm(state) {
  const { step } = state;
  switch (step) {
    case 1:
      return validateStep1(state);
    default:
      return false;
  }
}
/**
 *
 * @param {*} values
 * @returns
 */
async function addEmployee(values) {
  const format1 = "YYYY-MM-DD HH:mm:ss";
  const { USR_BIRTHDAY, ...usr } = values;
  const rows = {
    ...(USR_BIRTHDAY
      ? { USR_BIRTHDAY: moment(USR_BIRTHDAY).format(format1) }
      : {}),
    ...usr,
  };
  try {
    const result = await create(rows);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
/**
 *
 * @returns
 */
async function findAll() {
  try {
    const result = await getAll();
    const { data } = result;
    let mapper = [];
    if (data.length) {
      for (const d of data) {
        mapper.push(employeeItem(d));
      }
    }
    return Promise.all(mapper);
  } catch (error) {
    console.log(`findAll : ${error}`);
    return false;
  }
}
/**
 *
 * @param {*} guid
 * @returns
 */
async function findOne(guid) {
  try {
    const result = await getByGuid(guid);
    return employeeItem(result.data);
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 *
 * @param {*} data
 * @param {*} guid
 * @returns
 */
async function update(data, guid) {
  try {
    const result = await setByGuid(data, guid);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function deleteEmployee(guid) {
  try {
    const result = await deleteByGuid(guid);
    return result.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
/**
 *
 * @returns
 */
async function findContactsByGuid(guid) {
  try {
    const result = await getContactsByRhGuid(guid);
    const { data } = result;
    let mapper = [];
    if (data.length) {
      for (const d of data) {
        mapper.push(contactItem(d));
      }
    }
    return Promise.all(mapper);
  } catch (error) {
    console.log(`findAll : ${error}`);
    return false;
  }
}
export default {};
export {
  ifErrorExistInForm,
  addEmployee,
  findAll,
  findOne,
  update,
  deleteEmployee,
  findContactsByGuid,
};
