import React, { Component } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { initState } from "../controller/employee.model";
import {
  ifErrorExistInForm,
  addEmployee,
} from "../controller/employee.controller";

export class Content extends Component {
  state = {
    step: 1,
    ...initState(),
    results: "",
    errors: false,
  };

  nextStep = async () => {
    const { step, results, errors, ...values } = this.state;
    console.log(step);
    if (step === 2) {
      const result = await addEmployee(values);
      await this.setState({ results: result });
      await this.setState({ step: step + 1 });
    } else {
      const errorExist = await ifErrorExistInForm(this.state);
      if (!errorExist) {
        await this.setState({ step: step + 1 });
        await this.setState({ errors: false });
        this.props.steps(step + 1);
      } else {
        await this.setState({ errors: errorExist });
      }
    }
  };

  prevStep = async () => {
    const { step } = this.state;
    await this.setState({ step: step - 1 });
    await this.props.steps(step - 1);
  };

  inputChange = (input) => (e) => {
    if (e.value || e.value === 0) {
      this.setState({
        [input]: e.value,
      });
    } else if (e.target.value || e.target.value === 0) {
      this.setState({
        [input]: e.target.value,
      });
    } else {
    }
  };
  render() {
    const { step, results } = this.state;
    const { state } = this;

    switch (step) {
      case 1:
        return (
          <Step1
            nextStep={this.nextStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 3:
        return (
          <Step3
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChange={this.inputChange}
            values={state}
          />
        );
      case 4:
        return <Step4 values={results} />;
    }
  }
}

export default Content;
