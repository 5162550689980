import React, { Component } from "react";
import Select from "react-select";
import { OPTION } from "../../controller/employee.const";
import { validatorUpdate } from "../../controller/employee.validator";

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false,
      activeBirthday: false,
    };
  }
  inputChange = (input) => async (e) => {
    const {
      data: { employe },
    } = this.props;
    if (e.value || e.value === 0) {
      this.setState({
        [input]: e.value,
      });
    } else if (e.target.value || e.target.value === 0) {
      this.setState({
        [input]: e.target.value,
      });
    } else {
    }

    const { exist } = validatorUpdate(this.state, employe);
    if (exist) {
      this.setState({ change: true });
    } else {
      this.setState({ change: false });
    }
  };

  activeChangeDate = (input) => async (e) => {
    const { activeBirthday } = this.state;

    if (input === "USR_BIRTHDAY") {
      this.setState({ activeBirthday: !activeBirthday });
    }
  };
  handleClick = async () => {
    const { data, canUpdate } = this.props;
    const { employe } = data;
    const { change, activeBirthday, ...payload } = this.state;
    const changeExist = validatorUpdate(this.state, employe);
    await canUpdate(payload, changeExist);
  };
  render() {
    const { employe, error } = this.props.data;
    const { activeBirthday } = this.state;

    return (
      <div class="card card-custom ">
        <div class="card-body">
          <div class="tab-content">
            <div
              class="tab-pane show active px-7"
              id="kt_user_edit_tab_1"
              role="tabpanel"
            >
              <div class="row ">
                <div class="col-xl-7">
                  <div class="row">
                    <label class="col-3"></label>
                    <div class="col-9">
                      <h6 class="text-dark font-weight-bold mb-10">
                        Informations générales
                      </h6>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Prénom
                    </label>
                    <div class="col-9">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={employe.USR_FIRSTNAME}
                        onChange={this.inputChange("USR_FIRSTNAME")}
                        name="USR_FIRSTNAME"
                      />
                    </div>
                    <span class="form-text text-muted">
                      {error.USR_FIRSTNAME}
                    </span>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Nom
                    </label>
                    <div class="col-9">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={employe.USR_LASTNAME}
                        onChange={this.inputChange("USR_LASTNAME")}
                        name="USR_LASTNAME"
                      />
                    </div>
                    <span class="form-text text-muted">
                      {error.USR_LASTNAME}
                    </span>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Email
                    </label>
                    <div class="col-9">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={employe.USR_EMAIL}
                        onChange={this.inputChange("USR_EMAIL")}
                        name="USR_EMAIL"
                      />
                      <span class="form-text text-muted">
                        {error.USR_EMAIL}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Date d'anniversaire
                    </label>
                    <div class="col-9">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={employe.USR_BIRTHDAY}
                        onChange={this.inputChange("USR_BIRTHDAY")}
                        name="USR_BIRTHDAY"
                      />
                      <span class="form-text text-muted">
                        {error.USR_BIRTHDAY}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Adresse
                    </label>
                    <div class="col-9">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={employe.USR_ADDRESS}
                        onChange={this.inputChange("USR_ADDRESS")}
                        name="USR_ADDRESS"
                      />
                      <span class="form-text text-muted">
                        {error.USR_ADDRESS}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Code postal
                    </label>
                    <div class="col-9">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={employe.USR_ZIP_CODE}
                        onChange={this.inputChange("USR_ZIP_CODE")}
                        name="USR_ZIP_CODE"
                      />
                      <span class="form-text text-muted">
                        {error.USR_ZIP_CODE}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Poste
                    </label>
                    <div class="col-9">
                      <Select
                        name="USR_POST"
                        options={OPTION.OPTION_POST}
                        value={{
                          value: employe.USR_POST,
                          label: employe.USR_POST,
                        }}
                        onChange={this.inputChange("USR_POST")}
                      />

                      <span class="form-text text-muted">{error.USR_POST}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-3 text-lg-right text-left">
                      Téléphone
                    </label>
                    <div class="col-9">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        defaultValue={employe.USR_PHONE}
                        onChange={this.inputChange("USR_PHONE")}
                        name="USR_PHONE"
                      />
                      <span class="form-text text-muted">
                        {error.USR_PHONE}
                      </span>
                    </div>
                  </div>
                  <div class="form-group text-right">
                    <div class="col-9">
                      <button
                        onClick={this.handleClick}
                        class="btn btn-light-primary font-weight-bold "
                      >
                        Enregister
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
