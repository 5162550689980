import API from "../utils/api/api";
/**
 *
 * @param {*} token
 * @returns
 */
function findOne(token) {
  const ajaxAction = "load";
  return API.post("adm_ses.php", {
    SES_GUID: token,
    ajaxAction,
  });
}

/**
 *
 * @param {*} token
 * @returns
 */
function destroy(token) {
  const ajaxAction = "delete";
  return API.post("adm_ses.php", {
    SES_GUID: token,
    ajaxAction,
  });
}

export default {};
export { destroy, findOne };
