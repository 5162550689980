import React, { Component } from "react";
import * as ReactBootStrap from "react-bootstrap";
import UpdateFournisseur from "../controller/model/UpdateFournisseur";
import validatorInfo from "../controller/error/validatorInfo";
import GetFournisseur from "../controller/model/GetFournisseur";
import moment from "moment";

export class DetailsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateError: false,
      change: false,
      success: false,
      error: {
        email: "",
      },
      fou: {
        FOU_NAME: "",
        FOU_ZONE: "",
        FOU_ZIP_CODE: "",
        FOU_ADDRESS: "",
        FOU_COUNTRY: "",
        FOU_EMAIL: "",
        FOU_PHONE: "",
        FOU_UPDATED: "",
        FOU_CREATED: "",
        FOU_GUID: "",
      },
      FOU_NAME: "",
      FOU_ZONE: "",
      FOU_ZIP_CODE: "",
      FOU_ADDRESS: "",
      FOU_COUNTRY: "",
      FOU_EMAIL: "",
      FOU_PHONE: "",
      loading: false,
      buttonLoading: false,
    };
    this.inputChange = this.inputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  inputChange = input => async e => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    const changeExist = validatorInfo(this.state);
    if (changeExist) {
      this.setState({ change: true });
    }
  };
  handleClick = async () => {
    this.setState({ buttonLoading: true });
    const { FOU_GUID } = this.state.fou;
    const changeExist = validatorInfo(this.state);
    const { exist = false, errors = false, message = "" } = changeExist;
    if (exist) {
      const res = await UpdateFournisseur(this.state, FOU_GUID);
      if (res) {
        await this.setState({ success: true });
      } else {
        await this.setState({ updateError: true });
      }
    }
    if (errors) {
      await this.setState({ error: { email: message.email } });
    }
  };

  async componentWillMount() {
    const { guid } = this.props;
    const fou = await GetFournisseur(guid);
    if (fou.FOU_GUID) {
      this.setState({ fou: fou });
      this.setState({ loading: true });
    }
  }
  Rerender = async () => {
    await this.setState({
      success: false,
      change: false,
      buttonLoading: false,
      updateError: false,
      error: {
        email: "",
      },
    });
    await this.forceUpdate();
  };
  render() {
    const {
      FOU_NAME,
      FOU_ZONE,
      FOU_ZIP_CODE,
      FOU_ADDRESS,
      FOU_COUNTRY,
      FOU_EMAIL,
      FOU_PHONE,
      FOU_UPDATED,
      FOU_CREATED,
    } = this.state.fou;

    const {
      loading,
      error = false,
      change = false,
      buttonLoading = false,
      success = false,
      updateError = false,
    } = this.state;

    return (
      <>
        {loading ? (
          <div
            class="tab-pane show active px-7"
            id="kt_user_edit_tab_1"
            role="tabpanel"
          >
            <div class="row ">
              <div class="col-xl-7">
                <div class="row">
                  <label class="col-3"></label>
                  <div class="col-9">
                    <h6 class="text-dark font-weight-bold mb-10">
                      Informations générales
                    </h6>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Nom cabinet
                  </label>
                  <div class="col-9">
                    <input
                      class="form-control form-control-lg  form-control-solid"
                      type="text"
                      defaultValue={FOU_NAME}
                      onChange={this.inputChange()}
                      name="FOU_NAME"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Secteur d'activité
                  </label>
                  <div class="col-9">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      defaultValue={FOU_ZONE}
                      onChange={this.inputChange()}
                      name="FOU_ZONE"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Téléphone
                  </label>
                  <div class="col-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="la la-phone"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        defaultValue={FOU_PHONE}
                        placeholder="0033099887733"
                        onChange={this.inputChange()}
                        name="FOU_PHONE"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Email
                  </label>
                  <div class="col-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="la la-at"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        defaultValue={FOU_EMAIL}
                        onChange={this.inputChange()}
                        name="FOU_EMAIL"
                      />
                    </div>
                    <span>{error && error.email}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Adresse
                  </label>
                  <div class="col-9">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      defaultValue={FOU_ADDRESS}
                      onChange={this.inputChange()}
                      name="FOU_ADDRESS"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Code postal
                  </label>
                  <div class="col-9">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      defaultValue={FOU_ZIP_CODE}
                      onChange={this.inputChange()}
                      name="FOU_ZIP_CODE"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Pays
                  </label>
                  <div class="col-9">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      defaultValue={FOU_COUNTRY}
                      onChange={this.inputChange()}
                      name="FOU_COUNTRY"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Date de création
                  </label>
                  <div class="col-9">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      value={
                        FOU_CREATED && moment(FOU_CREATED).format("DD/MM/YYYY")
                      }
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-3 text-lg-right text-left">
                    Date de modification
                  </label>
                  <div class="col-9">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      value={
                        FOU_UPDATED && moment(FOU_UPDATED).format("DD/MM/YYYY")
                      }
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                </div>
                <div class="form-group text-right">
                  <div class="col-9">
                    <button
                      variant="primary"
                      onClick={!success ? this.handleClick : this.Rerender()}
                      class="btn btn-light-primary font-weight-bold "
                      disabled={!change}
                    >
                      {buttonLoading ? "Loading…" : "Enregister"}
                    </button>
                  </div>
                  <span> {updateError ? "erreur" : ""}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ReactBootStrap.Spinner animation="border" />
        )}
      </>
    );
  }
}

export default DetailsInfo;
