export default {};
const webPath = {
  dev: "https://crm.k2mdeveloppementservice.site/#/",
  // dev: "https://crm-test.joediouf.com/#/",
  // dev: "http://localhost:3000/#/",
};
const apiPath = {
  // dev: "https://www.crm-api-test.joediouf.com/ws/",
  dev: "https://www.api-crm.k2mdeveloppementservice.site/ws/",
  // dev: "http://localhost:8001/ws/",
};
export { webPath, apiPath };
