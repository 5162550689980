"use strict";
import React from "react";
import Notifications from "./Notifications";
import Languages from "./Languages";
import UserProfil from "./UserProfil";
const Topbar = (props) => {
  return (
    <div class="topbar">
      {/* <Notifications />,*/}
      <Languages />,
      <UserProfil user={props.user} />
    </div>
  );
};

export default Topbar;
