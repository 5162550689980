import moment from "moment";

function initProps() {
  return {
    HIS_VARIABLE: "",
    HIS_USER_GUID: "",
    HIS_OBJECT_GUID: "",
  };
}

function historiqueItem(historique) {
  if (!historique) {
    return initProps();
  }
  const { HIS_CREATED, USR_FIRSTNAME, USR_LASTNAME, ...his } = historique;
  return {
    USR_NAME: `${USR_FIRSTNAME || ""} ${USR_LASTNAME || ""}`,
    HIS_CREATED: moment(HIS_CREATED).format("DD/MM/YYYY à HH:MM"),
    ...his,
  };
}

export default {};
export { initProps, historiqueItem };
