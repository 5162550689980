import API from "../../../utils/api/api";

const GetFournisseur = async guid => {
  const val = { token: "22392", ajaxAction: "load", FOU_GUID: guid };
  try {
    const response = await API.post("adm_fou.php", val);
    if (response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    return false;
  }
};

export default GetFournisseur;
