import API from "../../utils/api/api";
import { getToken } from "../../session/session.controller";

/**
 *
 * @returns
 */
const getAll = async CON_GUID => {
  const token = await getToken();
  return await API.post("adm_his.php", {
    token,
    ajaxAction: "list",
    CON_GUID,
  });
};

export default {};
export { getAll };
