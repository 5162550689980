"use strict";
import React from "react";

const CrmName = () => {
  return (
    <div
      class="header-menu-wrapper header-menu-wrapper-left"
      id="kt_header_menu_wrapper"
    >
      <div
        id="kt_header_menu"
        class="header-menu header-menu-mobile header-menu-layout-default"
      >
        <ul class="menu-nav">
          <li class="menu-item menu-item-active" aria-haspopup="true">
            <span class="menu-link">
              <span class="menu-text">K2M DEVELOPPEMENT </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CrmName;
